<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATARE CANAPELE</h1>
        <img src="/assets/images/services/curatare-canapele.jpg" alt="curatare canapele">
      </div><!-- Section Header /- -->
      <p>Indiferent de tipul tapiteriei, vei obtine o canapea perfect curata si igienizata, in culorile frumoase si vii
        de la inceput.
        Oferim servicii profesionale de <strong>curatare canapele</strong>, fotolii si scaune acoperite cu tapiterie in
        Bucuresti si
        Ilfov.
        Preturile variaza intre <span class="green-highlight">20 lei</span> si <span class="green-highlight">250
          lei</span> in functie de tipul obiectului de mobilier si de numarul de locuri al
        canapelei.
      </p>
      <hr>

      <h2>Servicii profesionale de curatare canapele la domiciliu</h2>
      <p>
        <strong>Cele mai des folosite obiecte de mobilier sunt si cele care capteaza cele mai multe bacterii.</strong>
        <br>
        Zilnic, canapeaua intra in contact cu numeroase persoane diferite, daca este sa ne gandim la <strong>canapelele
          sau
          fotoliile din cadrul unui birou</strong> sau din salile de asteptare ale diferitelor tipuri de cabinete
        medicale.
      </p>
      <p>Acasa, <strong>canapeua reprezinta centru locuintei</strong>. Aici ne adunam de cele mai multe ori cu scopul de
        a ne relaxa, de
        a viziona un film in familie sau cu prietenii, mancam diferite snack-uri, iar uneori mai si dormim. Si daca mai
        punem la socoteala si existenta unui patruped, deja situatia devine si mai complexa. </p>

      <p>Chiar daca canapelele nu prezinta pete sau urme vizibile de murdarie, aceasta nu este cel mai igienic loc din
        casa. Aspiratorul obisnuit curata superficial doar praful, fara sa aiba capacitatea de a elimina elementele
        daunatoare sanatatii noastre. </p>
      <p>Echipa noastra foloseste <strong>cea mai performanta si eficienta metoda de curatare</strong>, <span
          class="green-highlight">prin injectie-extractie</span>, care
        garanteaza patrunderea in profunzime si eliminarea murdariei, a prafului, dar di a bacteriilor si a acarienilor.
      </p>

      <app-service-section></app-service-section>
      <h3>Beneficii pentru curatare profesionala canapea:</h3>
      <app-link-services>
        <app-links
          linkTitle="Canapeaua devine nu doar curata, ci mai ales igienizata, devenind un loc sigur de asteptare, de relaxare sau de somn"
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Sunt indepartati acarienii, praful, bacteriile si alte elemente care pot provoca afectiuni respiratorii si alergii"
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Dipsar mirosurile neplacute, intreaga incapere capatand un aer mai sanatos, mai curat, mai placut"
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Culorile tapiteriei sunt reimprospatate, devenind mai vii, aspectul canapelei fiind similar cu momentul achizitiei"
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Patrundem in profunzimea materialului cu solutii profesionale de curatat, nu doar la suprafata, apoi extragem toata apa si murdaria prin intermediul aspiratorului de injectie-extractie"
          linkIcon="checked"></app-links>
        <app-links linkTitle="Se usuca rapid, eliminand riscul de aparitie a mucegaiului" linkIcon="checked">
        </app-links>
      </app-link-services>

      <p>Procedura dureaza intre 30 - 90 de minute, in urma noastra ramanand o <strong>canapea curata, igienizata si uscata</strong>. <br>
        Oferim servicii de curatat canapele la domiciliu in orice locatie din Bucuresti sau Ilfov. Tot ce trebuie sa
        faci este sa ne suni pentru a programa ziua si ora.
      </p>

    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
