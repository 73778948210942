<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE DUPA CONSTRUCTOR</h1>
        <img src="/assets/images/services/curatenie-dupa-constructor.jpg" alt="curatenie dupa constructor">
      </div><!-- Section Header /- -->
      <p>Cand vine vorba despre curatenie post-constructie sau post renovare, cantitatea de resturi rezultate in urma
        constructorilor poate varia destul de mult, asa ca si preturile variaza intre <span class="green-highlight">8
          RON /mp</span> si <span class="green-highlight">15 RON / mp</span>, in
        functie de complexitatea proiectului. De aceea, estimarea corecta se poate face doar dupa o discutie cu unul
        dintre membrii echipe noastre.
      </p>
      <hr>

      <h2>De ce sa apelezi la serviciul de curatenie dupa constructor?</h2>
      <ul class="constructor-services">
        <li>Siguranta persoanelor care vor ocupa spatiul reprezinta o prioritate. Nu iti face griji cu privire la
          eventualele cuie ramase sau a prafului fin care poate cauza probleme de respiratie.</li>
        <li>Echipa noastra are abilitatile si echipamentele potrivite pentru a face acest tip de curatenie - prin
          urmare, vom obtine rezultate profesionale. Fara stropi de vopsea, autocolante sau praf periculos.</li>
        <li>Curatam in profunzime, rapid si cu rezultate profesionale, pentru ca mutarea in noul spatiu sa nu fie
          intarziata de aspectul curateniei.</li>
      </ul>

      <app-service-section></app-service-section>
      <h3>Curatenie dupa constructor sau dupa renovare presupune:</h3>
    
    
      <app-link-services>
        <app-links linkTitle="Debarasarea resturilor (moloz, ambalaje, cartoane) " linkIcon="checked"></app-links>
        <app-links linkTitle="Stergerea de praf a peretilor cu ajutorul echipamentelor speciale" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea corpurilor de iluminat" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea plintelor, prizelor, intrerupatoarelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea amanuntita a tocurilor si a usilor" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalarea geamurilor, chiar si a celor greu accesibile" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea si igienizarea grupurilor sanitare" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea si igienizarea bucatariei" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea scarilor de acces si a balustradelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea amanuntita a caloriferelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalarea mecanizata a pardoselelor – mocheta, linoleum, gresie, pietre naturale, parchet;" linkIcon="checked"></app-links>
        <app-links linkTitle="Slefuire si cristalizare marmura si mozaic" linkIcon="checked"></app-links>
        
        
      </app-link-services>
      <p>In curand te vei putea relaxa in noul tau spatiu, asa ca lasa treaba murdara in seama profesionistilor. </p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
