<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATARE TAPITERIE AUTO SI DETAILING</h1>
        <img src="/assets/images/services/curatare-tapiterie-auto-detailing-auto.jpg"
          alt="curatare tapiterie auto detailing auto">
      </div><!-- Section Header /- -->
      <p>Sa faci <strong>curatenie in masina</strong> poate deveni o activitate dificila si stresanta, mai ales atunci
        cand timpul si uzura isi fac simtite prezenta prin mirosuri si urme greu de indepartat.
        Iti imaginezi cum ar fi sa cureti singur un autocar, un tir, o rulota, sau chiar un avion? Partea buna este ca
        nici nu trebuie sa te gandesti la asta, pentru ca aici intervenim noi. Venim pregatiti cu <strong>solutii
          profesionale</strong>
        si <strong>aparatura ultra performanta</strong>, care ne permite sa facem dintr-o treaba dificila, o joaca de
        copii.
        <strong>Preturile depind</strong> de gradul de murdarie si de numarul de locuri ale vehiculului, asa ca <span
          class="green-highlight">te incurajam sa ne
          contactezi</span> pentru a stabili un pret corect.

      </p>
      <hr>

      <h2>Curatare tapiterie auto - perfect curat chiar exista</h2>
      <p>Masina personala a devenit un accesoriu indispensabil in zilele noastre, care ne ajuta sa ajungem la locul de
        munca, sa ducem si sa luam copiii de la scoala, sa mergem in vacante, sa ducem cainele la veterinar. Toate
        acestea, impreuna cu fumatul si consumatul de alimente in interiorul acesteia, duc in timp la murdarirea
        inevitabila, imbacsirea cu mirosuri si preluarea de diferite bacterii.
        <br> Daca e sa vorbim despre cabinele de tir, rulote si alte mijloace de transport in comun, este clar ca
        murdarirea este mai frecventa, cu cat traficul de persoane este mai mare.
        <br>Ce putem face pentru tapiteria auto?
      </p>
      <ul class="constructor-services">
        <li>Aspiram cu un aspirator capabil sa extraga cele mai mici particule de praf</li>
        <li>Samponam cu produse profesionale de curatenie care nu afecteaza materialul tapiteriei</li>
        <li>Extragem toata solutia lichida, impreuna cu murdaria din profunzimea tapiteriei</li>
        <li>Odorizam si igienizam</li>
      </ul>

      <app-service-section></app-service-section>
      <h3>Detailing auto in Bucuresti si Ilfov pentru toate mijloacele de transport</h3>
      <p>Realizam <strong>detailing auto</strong> pentru toate partile esentiale ale masinii, de la plafon, stalpi si
        centuri, pana la
        banchete, scaune, mocheta, usi, geamuri, bord si portbagaj.
        <br><strong>Ce servicii oferim?</strong>
      </p>


      <app-link-services>
        <app-links linkTitle="curatat tapiterii si detailing autoturisme" linkIcon="checked"></app-links>
        <app-links linkTitle="curatat tapiterii si detailing cabine de tir" linkIcon="checked"></app-links>
        <app-links linkTitle="curatat tapiterii si detailing autobuze, autocare, microbuze" linkIcon="checked">
        </app-links>
        <app-links linkTitle="curatat tapiterii si detailing rulote" linkIcon="checked"></app-links>
        <app-links linkTitle="curatat tapiterii si detailing avioane" linkIcon="checked"></app-links>
        <app-links linkTitle="servicii de polish auto" linkIcon="checked"></app-links>
        <app-links linkTitle="spalat exterior" linkIcon="checked"></app-links>

      </app-link-services>
      <p>Vrei si tu o masina perfect curata? Suna-ne sau trimite un mail in care sa ne spui ce putem face pentru tine,
        iar noi iti vom da toate detaliile necesare.</p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
