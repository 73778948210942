<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>SPALARE MECANIZATA PODELE</h1>
        <img src="/assets/images/services/spalare-mecanizata-podele.jpg" alt="spalare mecanizata podele">
      </div><!-- Section Header /- -->
      <p>Activitatea de curatare mecanizata de face cu echipamente profesionale si utilizand solutii specializate pe
        fiecare tip de suprafata in parte.
        <strong>Preturile se calculeaza</strong> in functie de gradul de murdarie al pardoselei dure si incep de la
        <span class="green-highlight">7 lei/mp</span>. Pentru o
        estimare corecta, te rugam sa ne contactezi telefonic sau prin email.
      </p>
      <hr>

      <h2>Spalare mecanizata podele - curatenie fara compromisuri</h2>
      <p>Pardoselile dure, precum linoleum, gresie, piatra naturala, marmura granit, necesita odata cu trecerea timpului
        o curatare executata cu utilaje profesionale, care sa elimine murdaria veche si profunda. </p>

      <h2>Cum te putem ajuta?</h2>
      <ul class="constructor-services">
        <li><strong>Curatare mencanizata granit si gresie</strong><br>
          Suprafetele dure, precum granitul sau gresia, aflate in interiorul sau exteriorul cladirilor sunt adesea
          afectate de murdaria sedimentata in timp si necesita o actiune de curatare mult mai intensa decat ar putea fi
          facuta cu mopul obisnuit. Folosim solutii speciale, pentru a elimina petele de vopsea, chit de rosturi,
          calcar, uleiuri si alte substante greu de curatat.
        </li>
        <li><strong>Curatare mecanizata marmura</strong><br>
          Pardoseala din marmura necesita o atenite suplimentara atunci cand este curatata folosind solutii chimice
          profesionale. Echipa noastra are experienta necesara pentru a asigura o curatare perfecta si in siguranta.
        </li>
        <li><strong>Curatare mencanizata granit si gresie</strong><br>
          Suprafetele dure, precum granitul sau gresia, aflate in interiorul sau exteriorul cladirilor sunt adesea
          afectate de murdaria sedimentata in timp si necesita o actiune de curatare mult mai intensa decat ar putea fi
          facuta cu mopul obisnuit. Folosim solutii speciale, pentru a elimina petele de vopsea, chit de rosturi,
          calcar, uleiuri si alte substante greu de curatat.
        </li>
        <li><strong>Curatare mecanizata linoleum | tarket | vopsea epoxidica</strong><br>
          Suprafetele moi, precum cele acoperite cu linoleum, tarkett sau vopsea epoxidica necesita folosirea unor
          solutii decapante puternice si o atentie deosebita. Curatam in siguranta orice tip de suprafata.
        </li>
      </ul>

      <app-service-section></app-service-section>
      <h3>Beneficiile curatarii mecanizate a podelelor</h3>


      <app-link-services>
        <app-links linkTitle="Murdaria veche si dificila este eliminata simplu si rapid prin intermediul utilajelor profesioale pe care le folosim" linkIcon="checked"></app-links>
        <app-links linkTitle="Podelele isi recapata stralucirea initiala, iar curatarea se face 100% in siguranta, pentru ca folosim solutii profesionale" linkIcon="checked"></app-links>
        <app-links linkTitle="Costurile sunt reduse in raport cu rezultatele care se obtin - o pardoseala curata, cu aspect uniform si ingrijit" linkIcon="checked"></app-links>
      </app-link-services>
      <p>Facem fata celor mai dificile tipuri de murdarie atunci cand vine vorba despre podelele dure sau soft. <strong>Ne deplasam oriunde in Bucuresti si Ilfov</strong> pentru a reda stralucirea initiala a pardoselii tale.</p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
