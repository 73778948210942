<section class="section-perfect-clean">
    <h1>
      DE CE SA ALEGI <strong>CURATENIE PERFECTA</strong>?
    </h1>
    <div class="section-perfect-clean__inner">
      <div class="container">
        <ul>
          <li>
            <div class="title">
              <img src="/assets/images/icon/hand-wash.svg" alt="">
              <h4>Dezinfectam & Igienizam</h4>
            </div>
           
            <p>Utilizam dezinfectant biocid, avizat de Ministerul Sanatalii, pe toate suprafelele pe care le curatam. Stim
              cat este de important acest pas, asa ca nu-l omitem niciodata.</p>
          </li>
          <li>
            <div class="title">
              <img src="/assets/images/icon/vacuum-cleaner.svg" alt="">
              <h4>Aparatura Ultraperformanta</h4>
            </div>
            
            <p>Timpul este cea mai importanta resursa in zilele noastre. Aparatura pe care o folosim ne ajuta sa ne
              terminam treaba repede, nu doar bine</p>
          </li>
        </ul>
        <img src="/assets/images/firma-curatenie-bucuresti-si-ilfov.jpg" alt="">
        <ul>
          <li>
            <div class="title">
              <img src="/assets/images/icon/cleaning.svg" alt="">
              <h4>Experienta</h4>
            </div>
            
            <p>Relaxeaza-te, esti pe maini bune :) Stim sa abordam fiecare situatie de curatenie in parte. Avem cu cine,
              avem cu ce. Tu spune-ne doar unde.</p>
          </li>
          <li>
            <div class="title">
              <img src="/assets/images/icon/clean.svg" alt="">
              <h4>Produse Profesionale</h4>
            </div>
           
            <p>Atunci cand folosesti produsele potrivite fiecarui tip de suprafata, procesul de curatare devine o joaca de
              copii. Curata in profunzime si au actiune de durata.</p>
          </li>
        </ul>
      </div>
    </div>
  </section>