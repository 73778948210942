<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE SPATII COMERCIALE</h1>
        <img src="/assets/images/services/curatenie-spatii-comerciale.jpg" alt="curatenie spatii comerciale">
      </div><!-- Section Header /- -->
      <p>Echipa noastra face curatenie perfecta in toate tipurile de <strong>spatii comerciale din Bucuresti si din
          Ilfov</strong>, de la
        suprafete mari si foarte mari, pana la cele mai mici detalii, astfel incat atat comerciantii, cat si clientii sa
        fie multumiti de calitatea locatiei.
      </p>
      <hr>

      <h2>Prima impresie conteaza - alege cele mai bune servicii de curatenie spatii comerciale</h2>
      <p>Fie ca este vorba de curatenia si de intretinerea unui spatiu comercial, a unui showroom sau sediu bancar,
        echipa noastra de profesionisti in curatenie si igienizare acorda o importanta foarte mare unui astfel de loc
        unde serviciile si relatiile cu clientii trec prin filtrul primei impresii.
      </p>
      <p>Venim in intampinarea nevoilor tale si iti punem la dispozitie <strong>servicii de curatenie generala </strong>
        sau <strong>servicii de
          intretinere</strong> a spatiilor comerciale. Un aspect curat si proaspat al spatiului unde iti desfasori
        activitatea este
        mult mai atractiv pentru angajati si clienti si, prin urmare, te va ajuta sa ai o afacere prospera.</p>

      <p>În functie de domeniul de activitate si de nevoile tale, efectuam servicii cu program personalizat si iti punem
        la dispozitie o echipa calificata si responsabila pentru tine si clientii tai. </p>

      <app-service-section></app-service-section>
      <h3>Ce servicii de curatenie iti oferim?</h3>
      <app-link-services>
        <app-links linkTitle="Curatarea si igienizarea bailor pentru clienti si angajati" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatenie generala in zona de desfasurare a activitatii sau in spatiul centrului comercial" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea scarilor rulante" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalarea geamurilor si a vitrinelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalarea mecanizata a podelelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Aspirarea si curatarea prin injectie-extractie a mochetelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea canapelelor" linkIcon="checked"></app-links>
      </app-link-services>

    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
