<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE BIROURI</h1>
        <img src="/assets/images/services/curatenie-birouri.jpg" alt="curatenie birouri">
      </div><!-- Section Header /- -->
      <p>Preturile pentru serviciile de curatenie birouri incep de la <span class="green-highlight">5 RON/mp</span> si
        pot sa varieze in functie de numerosi factori. De aceea, estimarea corecta se poate face doar dupa o discutie cu
        unul dintre membrii echipe noastre.
      </p>
      <hr>

      <h2>Curatenie birouri? Ai o intreaga echipa de profesionisti alaturi de tine</h2>
      <p>Intr-o cladire de birouri pe mai multe etaje, unde sunt foarte multe firme care isi desfasoara activitatile, un
        singur om nu va face fata la tot volumul de munca, asa ca solutia perfecta in astfel de cazuri este angajarea
        unei echipe calificate in curatarea si dezinfectarea zonelor de lucru. Un aspect aparent banal, calificarea in
        acest domeniu inseamna pe langa mentinerea zilnica a ordinii si manevrarea solutiilor dezinfectante si
        detergentilor, si utilizarea unor utilaje speciale pentru o curatare: de la discuri de curatare pana la
        aspiratoare ce folosesc tehnologii eficiente de indepartarea prafului si improspatarea zonei de lucru.
      </p>

      <app-service-section></app-service-section>
      <h3>Ce servicii de curatenie iti oferim?</h3>
      <app-link-services>
        <app-links linkTitle="Intretinerea zilnica a birourilor" linkIcon="checked"></app-links>
        <app-links linkTitle="Colectarea gunoiului si spalarea vaselor" linkIcon="checked"></app-links>
        <app-links linkTitle="Igienizarea bucatariei si a bailor" linkIcon="checked"></app-links>
        <app-links linkTitle="Improspatarea aerului" linkIcon="checked"></app-links>
        <app-links linkTitle="Aspirare mochete" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalare mecanizata a mochetei si a gresiei" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalare geamuri chiar si cele greu accesibile, aflate la inaltime" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalare fatade de cladiri" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea si stergerea mobilierul, a aparaturii de birou" linkIcon="checked"></app-links>
        <app-links linkTitle="La final folosim folosind solutii profesionale antiseptice si dezinfectante pe toate suprafetele curatate" linkIcon="checked"></app-links>
      </app-link-services>

      <p>Poti alege intre serviciile de <strong>curatenie one-time</strong>, sau sub forma de <strong>abonament</strong>, cu frecventa in functie de necesitatile tale.</p>
     
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
