<section id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <!-- Section Header -->
    <div class="section-header">
      <h3><img src="/assets/images/icon/sep-icon.png" alt="sep-icon"> SERVICII DE CURATENIE PROFESIONALA</h3>
    </div><!-- Section Header /- -->

   
    
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-birouri.jpg" alt="curatenie birouri" />
        </figure>
          <div class="service-box-inner">
            <h4> Curatenie Birouri </h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-birouri']">vezi detalii</a>
          </div>
        </div>
  
      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-la-domiciliu.jpg" alt="curatenie la domiciliu" />
        </figure>
          <div class="service-box-inner">
            <h4> Curatenie la Domiciliu</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-la-domiciliu']">vezi detalii</a>
          </div>
        </div>

      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-spatii-comerciale.jpg" alt="curatenie spatii comerciale" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatenie Spatii Comerciale</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-spatii-comerciale']">vezi detalii</a>
          </div>
        </div>

      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-industriala.jpg" alt="curatenie spatii industriale" />
        </figure>
          <div class="service-box-inner">
            <h4> Curatenie Spatii Industriale</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-industriala']">vezi detalii</a>
          </div>
        </div>

      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-dupa-constructor.jpg" alt="curatenie dupa constructor" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatenie dupa Constructor</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-dupa-constructor']">vezi detalii</a>
          </div>
        </div>

      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatenie-dupa-evenimente.jpg" alt="curatenie dupa evenimente" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatenie dupa Evenimente</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-dupa-evenimente']">vezi detalii</a>
          </div>
        </div>

      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatare-tapiterie-auto-detailing-auto.jpg" alt="curatenie in masina" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatenie in Masina</h4>
            <a title="vezi detalii" [routerLink]="['curatare-tapiterie-auto-detailing']">vezi detalii</a>
          </div>
        </div>
    
      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatare-canapele.jpg" alt="curatare canapele" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatare Canapele</h4>
            <a title="vezi detalii" [routerLink]="['/curatare-canapele']">vezi detalii</a>
          </div>
        </div>
    
      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/curatare-mocheta.jpg" alt="curatare mocheta" />
        </figure>
          <div class="service-box-inner">
            <h4>Curatare Mocheta</h4>
            <a title="vezi detalii" [routerLink]="['/curatare-mocheta-covor']">vezi detalii</a>
          </div>
        </div>
    
      
        <div class="service-box col-md-4 col-sm-6">
         <figure>
             <img src="/assets/images/services/spalare-mecanizata-podele.jpg" alt="spalare mecanizata podele" />
        </figure>
          <div class="service-box-inner">
            <h4>Spalare Mecanizata Podele</h4>
            <a title="vezi detalii" [routerLink]="['/spalare-mecanizata-podele']">vezi detalii</a>
          </div>
        </div>
  


  </div><!-- container /- -->
</section>
<app-service-biocid></app-service-biocid>
<app-service-perfect-clean></app-service-perfect-clean>