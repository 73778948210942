<section id="welcome-section" class="welcome-section ow-section">
  <!-- container -->
  <div class="container">
    <div class="col-md-4 col-sm-5">
      <img src="/assets/images/services/firma-de-curatenie-bucuresti.png" alt="welcome">
    </div>
    <div class="col-md-8 col-sm-7 welcome-content">
      <!-- Section Header -->
      <div class="section-header">
        <h1>SERVICII PROFESIONALE DE CURATENIE IN BUCURESTI SI ILFOV</h1>
      </div><!-- Section Header /- -->
      <p><strong>Curatenia este temeiul sanatatii</strong>, asta este principiul dupa care echipa noastra se ghideaza,
        astfel încat, la final, clientii sa fie multumiti de serviciile noastre, pentru a se putea bucura de un spatiu
        curat si dezinfectat. </p>
      <p>Credem ca o lume mai curata ne ajuta pe toti sa fim mai bine organizati, mai focusati pe activitatile noastre
        cotidiene, mai relaxati acasa si totodata ne ajuta sa ne mentinem starea generala de bine.</p>

      <div class="welcome-content-box row">
        <div class="col-md-4 col-sm-6 welcome-box">
          <img src="/assets/images/icon/echipa-profesionista.svg" alt="echipa profesionista">
          
          <h4>Echipa Profesionista</h4>
          <p>Ne place ceea ce facem si asta se vede de fiecare data in urma noastra</p>
        </div>
        <div class="col-md-4 col-sm-6 welcome-box">
          <img src="/assets/images/icon/echipament-performant.svg" alt="echipament performant">
          <h4>Echipament Performant</h4>
          <p>Venim echipati cu aparatura si solutii de curatare super-eficiente</p>
        </div>
        <div class="col-md-4 col-sm-6 welcome-box">
          <img src="/assets/images/icon/repede-si-bine.svg" alt="repede si bine">
          <h4>Repede & Bine</h4>
          <p>Da, se poate. Cand iti iubesti munca, rezultatul se vede cu ochiul liber</p>
        </div>
      </div>
    </div>
    <hr>

    <h2>CE SERVICII DE CURATENIE ITI OFERIM?</h2>
    <p>Ne place sa credem ca orice problema are o solutie, iar la noi acestea devin provocari care ne incurajeaza sa
      venim cu solutii inovatoare. Fie ca ai nevoie de curatenie generala în spatiile de lucru comerciale si cladirile
      de birouri, de curatarea unei zone care a fost renovata, de dezinfectare sau curațare ecologica a unei zone de
      lucru industriale cu utilaje, echipa noastră indeplineste cu profesionalism orice tip de cerinta din partea
      clientilor nostri.</p>

    <ul class="service-section">


      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie la domiciliu</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie birouri</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie spatii comerciale</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie spatii industriale</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie dupa constructie / renovare</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie dupa evenimente</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatenie HoReCa</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatare canapele si mochete</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Curatare tapiterie auto si detailing auto</li>
      <li><img src="/assets/images/icon/checked.svg" alt="">Spalare mecanizata podele</li>

    </ul>

  </div><!-- container /- -->
  <app-service-biocid></app-service-biocid>
  <div class="container">
    <h2>SOLUTII SI TEHNOLOGII AVANSATE DE CURATENIE
    </h2>
    <p>Venim “inarmati” cu toata aparatura de care avem nevoie pentru a ne asigura ca plecam de la tine lasand in urma
      curatenie perfecta: </p>
    <ul class="constructor-services">


      <li>Mașină automată de spălat pardoseli Ghibli Rider</li>
      <li>Monodisc Ghibli</li>
      <li>Monodisc Taski</li>
      <li>Aspirator injecție/extracție Kaercher / Ghibli 5</li>
      <li>Aspirator lichid Tennant / Ghibli</li>
      <li>Aparat de curățat profesional cu aburi Kaercher</li>
      <li>Asprirator de praf Taski / Ghibli / Tennannt</li>
      <li>Motocoasă STIHL</li>
      <li>Motofierăstrău</li>
      <li>Compresor aer</li>
      <li>Aparat spălare fațade cu apă pură puraqleen</li>
      <li>Pistol curățare Twister</li>
      <li>Aparat curățare cu presiune de apă cu motor termic</li>
      <li>Aparat de curățare cu presiune electric</li>

    </ul>

    <app-gallery-carousel></app-gallery-carousel>
<app-service-testimonials></app-service-testimonials>

  </div>
</section>
