<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE SPATII INDUSTRIALE</h1>
        <img src="/assets/images/services/curatenie-industriala.jpg" alt="curatenie spatii industriale">
      </div><!-- Section Header /- -->
      <p>In orice domeniu este nevoie de curatenie si, periodic, de dezinfectarea spatiului de lucru. Pentru ca
        angajatii sa munceasca intr-un mediu sigur, curat si sanatos, este nevoie ca o echipa specializata in
        <strong>curatenie
          industriala</strong>.
        În functie de domeniul de activitate, echipa noastra va dezvolta solutii integrate, personalizate
        pentru zona afectata prin care sa indeparteze eficient bacteriile si depunerile de mizerie si praf.
      </p>
      <hr>

      <h2>Curatenie industriala - Cate probleme atatea solutii</h2>
      <p>Fie ca este nevoie de <strong>curatarea unei fabrici, hale sau a unui depozit</strong>, echipa noastra vine in
        ajutorul
        clientilor cu solutii inovatoare de curatare, cu focus pe detalii, astfel incat finalul sa fie pe masura
        asteptarilor, iar angajatii sa lucreze in siguranta si cu aceeasi performanta.
      </p>
      <p>Cea mai mare provocare a firmelor mari care utilizeaza masinarii si utilaje grele este sa le mentina curate,
        astfel incat acestea sa functioneze perfect si in siguranta cat mai mult timp. De aceea, pe langa mediul de
        lucru al angajatilor, seviciile de <strong>curatenie industriala</strong> preintampina si astfel probleme,
        oferind companiilor cu
        activitate industriala posibilitatea de a curata echipamentele si utilajele de depuneri de praf, reziduri si/sau
        ulei folosit in angrenarea acestora. </p>

      <app-service-section></app-service-section>
      <h3>Cu ce putem ajuta in domeniul curateniei industriale?</h3>
      <p>Expertii in curatenie industriala de la Monster Clean sunt instruiti cu proceduri specializate pe zona
        industriala, care necesita o atentie deosebita, fiind capabila sa curete nu doar spatiul ci si echipamentele si
        camioanele industriale.</p>
      <strong>În functie de activitatea firmei, serviciile de curatenie industriala pot fi:</strong>
      <app-link-services>
        <app-links linkTitle="Eliminarea substantelor chimice periculoase" linkIcon="checked">
        </app-links>
        <app-links linkTitle="Curatarea, dezinfectarea si intretinerea masinilor si altor echipamente"
          linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea zonelor de lucru" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatarea cantinelor, depozitelor si a halelor" linkIcon="checked"></app-links>
        <app-links linkTitle="Spalarea mecanizata a podelelor" linkIcon="checked"></app-links>
      </app-link-services>
      <p>Folosim echipamente si solutii speciale impreuna cu aparatura avansata astfel incat standardele de curatenie sa
        atinga cotele maxime, iar la final clientii sa fie multumiti de rezultatul obtinut.
      </p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
