<section>
  <div class="container">
    <div id="contents">
      <style type="text/css">
        .lst-kix_a7i3s71sxmbl-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_a7i3s71sxmbl-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_a7i3s71sxmbl-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_a7i3s71sxmbl-6>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_hkrc7y1ghhxn-2.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-2 0
        }

        .lst-kix_hkrc7y1ghhxn-7>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-7
        }

        .lst-kix_a7i3s71sxmbl-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_hkrc7y1ghhxn-1>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-1
        }

        .lst-kix_a7i3s71sxmbl-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_a7i3s71sxmbl-1>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_hkrc7y1ghhxn-5.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-5 0
        }

        .lst-kix_hkrc7y1ghhxn-2>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-2
        }

        ol.lst-kix_hkrc7y1ghhxn-1.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-1 0
        }

        .lst-kix_hkrc7y1ghhxn-5>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-5
        }

        ol.lst-kix_hkrc7y1ghhxn-8.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-8 0
        }

        ol.lst-kix_hkrc7y1ghhxn-2 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-1 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-4 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-3 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-0 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-4.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-4 0
        }

        .lst-kix_a7i3s71sxmbl-8>li:before {
          content: "\0025a0  "
        }

        .lst-kix_a7i3s71sxmbl-7>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_hkrc7y1ghhxn-7.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-7 0
        }

        ul.lst-kix_a7i3s71sxmbl-4 {
          list-style-type: none
        }

        .lst-kix_hkrc7y1ghhxn-0>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-0, decimal) ". "
        }

        ul.lst-kix_a7i3s71sxmbl-5 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-6 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-7 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-0.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-0 0
        }

        ul.lst-kix_a7i3s71sxmbl-8 {
          list-style-type: none
        }

        .lst-kix_hkrc7y1ghhxn-1>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-1, lower-latin) ". "
        }

        .lst-kix_hkrc7y1ghhxn-3>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-3, decimal) ". "
        }

        .lst-kix_hkrc7y1ghhxn-4>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-4, lower-latin) ". "
        }

        ol.lst-kix_hkrc7y1ghhxn-6 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-0 {
          list-style-type: none
        }

        .lst-kix_hkrc7y1ghhxn-2>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-2, lower-roman) ". "
        }

        .lst-kix_hkrc7y1ghhxn-4>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-4
        }

        ol.lst-kix_hkrc7y1ghhxn-5 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-1 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-8 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-2 {
          list-style-type: none
        }

        ol.lst-kix_hkrc7y1ghhxn-7 {
          list-style-type: none
        }

        ul.lst-kix_a7i3s71sxmbl-3 {
          list-style-type: none
        }

        .lst-kix_hkrc7y1ghhxn-8>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-8
        }

        ol.lst-kix_hkrc7y1ghhxn-6.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-6 0
        }

        ol.lst-kix_hkrc7y1ghhxn-3.start {
          counter-reset: lst-ctn-kix_hkrc7y1ghhxn-3 0
        }

        .lst-kix_hkrc7y1ghhxn-7>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-7, lower-latin) ". "
        }

        .lst-kix_hkrc7y1ghhxn-8>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-8, lower-roman) ". "
        }

        .lst-kix_hkrc7y1ghhxn-5>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-5, lower-roman) ". "
        }

        .lst-kix_hkrc7y1ghhxn-6>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-6
        }

        .lst-kix_hkrc7y1ghhxn-0>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-0
        }

        .lst-kix_hkrc7y1ghhxn-3>li {
          counter-increment: lst-ctn-kix_hkrc7y1ghhxn-3
        }

        .lst-kix_hkrc7y1ghhxn-6>li:before {
          content: ""counter(lst-ctn-kix_hkrc7y1ghhxn-6, decimal) ". "
        }

        ol {
          margin: 0;
          padding: 0
        }

        table td,
        table th {
          padding: 0
        }

        .c1 {
          margin-left: 36pt;
          padding-top: 0pt;
          padding-left: 0pt;
          padding-bottom: 23pt;
          line-height: 1.618;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c2 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 11pt;
          line-height: 1.618;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c11 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: left;
          height: 11pt
        }

        .c13 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify;
          height: 11pt
        }

        .c0 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c12 {
          padding-top: 0pt;
          padding-bottom: 10pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c4 {
          padding-top: 0pt;
          padding-bottom: 11pt;
          line-height: 1.618;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c6 {
          color: #000000;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c8 {
          text-decoration-skip-ink: none;
          -webkit-text-decoration-skip: none;
          color: #1155cc;
          text-decoration: underline
        }

        .c3 {
          background-color: #ffffff;
          max-width: 451.4pt;
          padding: 72pt 72pt 72pt 72pt
        }

        .c7 {
          padding: 0;
          margin: 0
        }

        .c10 {
          color: inherit;
          text-decoration: inherit
        }

        .c9 {
          margin-left: 36pt;
          padding-left: 0pt
        }

        .c5 {
          font-weight: 700
        }

        .title {
          padding-top: 0pt;
          color: #000000;
          font-size: 26pt;
          padding-bottom: 3pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .subtitle {
          padding-top: 0pt;
          color: #666666;
          font-size: 15pt;
          padding-bottom: 16pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        li {
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        p {
          margin: 0;
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        h1 {
          padding-top: 20pt;
          color: #000000;
          font-size: 20pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h2 {
          padding-top: 18pt;
          color: #000000;
          font-size: 16pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h3 {
          padding-top: 16pt;
          color: #434343;
          font-size: 14pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h4 {
          padding-top: 14pt;
          color: #666666;
          font-size: 12pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h5 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h6 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          font-style: italic;
          orphans: 2;
          widows: 2;
          text-align: left
        }

      </style>
      <p class="c4"><span>POLITICA DE CONFIDENȚIALITATE A SITE-ULUI www.curatenieperfecta.ro</span></p>
      <p class="c2"><span class="c0">Scopul acestei politici de confidențialitate este să vă explice ce date culegem, de
          ce le culegem și ce facem cu ele. </span></p>
      <p class="c12"><span>Proprietarul acestui site este </span><span class="c5">CLEANSPOT
          TEAM</span><span>&nbsp;</span><span class="c5">&nbsp;SRL, </span><span>denumit in continuare </span><span
          class="c5">Furnizorul, </span><span>care</span><span class="c5">&nbsp;</span><span class="c0">dorește să
          protejeze confidențialitatea datelor utilizatorilor care vizitează prezentul website. </span></p>
      <p class="c12"><span class="c0">Această declarație de confidențialitate este menită să te informeze despre
          felurile în care se colectează date pe acest site, felul în care se folosesc aceste informații și cum vom
          proteja informațiile pe care utilizatorii aleg să ni le ofere.</span></p>
      <p class="c2"><span class="c0">În general, informațiile personale se definesc ca informații prin care te putem
          identifica sau putem identifica acțiunile întreprinse pe site-ul www.curatenieperfecta.ro.</span></p>
      <p class="c2"><span class="c0">Această declarație de confidențialitate se aplică numai datelor colectate prin
          intermediul acest site.</span></p>
      <p class="c2"><span class="c6 c5">PREZENTELE TERMENE ȘI CONDIȚII SE REFERĂ LA:</span></p>
      <p class="c2"><span class="c6 c5">1. Vizitatorii de pe site-ul www.curatenieperfecta.ro</span></p>
      <p class="c2"><span>Când un vizitator intră pe site-ul </span><span>www.curatenieperfecta.ro</span><span
          class="c0">, folosim un serviciu, Google Analytics, pentru a colecta informații standard despre datele de
          logare pe internet și detalii despre comportamentul vizitatorilor. Folosim acest serviciu pentru a afla
          informații precum numărul de vizitatori pentru o anumită secțiune din site. Această informație este procesată
          în așa fel încât să nu poată fi identificată o anumită persoană.</span></p>
      <p class="c2"><span class="c5 c6">2. Vizitatorii site-ului care folosesc serviciul Whatsapp:</span></p>
      <p class="c2"><span class="c0">Folosim serviciile de chat Whatsapp pentru a răspunde solicitărilor clienților și
          potențialilor clienți în timp real.</span></p>
      <p class="c2"><span class="c0">Dacă folosești serviciul Whatsapp vom colecta următoarele informații: numarul tau
          de telefon și conținutul conversației. Dacă ni le furnizezi, adresa de email și numele vor fi colectate de
          asemenea. Informațiile vor fi stocate timp de 2 ani și nu vor fi transmise către alte
          companii/organizații.</span></p>
      <p class="c2"><span>Transcriptul conversației pe Whatsapp îl veți regăsi </span><span><a class="c10"
            href="https://www.google.com/url?q=https://www.facebook.com/messages&amp;sa=D&amp;ust=1588162389160000">în
          </a></span><span class="c0">aplicatia dvs. de Whatsapp.</span></p>
      <p class="c2"><span class="c6 c5">Ce tip de date colectam?</span></p>
      <ul class="c7 lst-kix_a7i3s71sxmbl-0 start">
        <li class="c1"><span class="c5">Dacă sunteți colaboratori sau potențiali colaboratori</span><span class="c0">,
            reprezentanți ai furnizorilor noștri; &nbsp;datele cu caracter personal pe care le prelucram sunt: numele și
            prenumele dvs., adresa de e-mail, numărul de telefon. </span></li>
        <li class="c1"><span class="c5">Daca sunteți clienți</span><span class="c0">, datele cu caracter personal pe
            care le prelucrăm sunt: nume, prenume, adresa, e-mail și &nbsp;număr de telefon, necesare executiei
            serviciului de curatenie solicitat.</span></li>
      </ul>
      <p class="c2"><span class="c6 c5">Cum folosim datele pe care le colectăm?</span></p>
      <p class="c2"><span class="c0">Vom folosi informațiile pe care ni le transmiți pentru a putea livra comanda
          solicitata de catre tine prin diferite mijloace: email, telefon, whatsapp, formular de contact. </span></p>
      <p class="c2"><span class="c0">Acest lucru poate include, de exemplu, transmiterea materialelor electronice sau
          oricăror informații solicitate de către tine și, dacă ne furnizezi numărul de telefon, poți primi un apel
          telefonic din partea noastră ca răspuns la o solicitare a ta.</span></p>
      <p class="c2"><span class="c6 c5">Ce facem cu datele colectate de la tine?</span></p>
      <p class="c2"><span class="c0">Nu vom vinde, schimba, sau distribui în orice fel datele tale personale, fără
          acordul tău explicit, cu excepția solicitărilor legale, conform prezentei politici de
          confidențialitate:</span></p>
      <ul class="c7 lst-kix_a7i3s71sxmbl-0">
        <li class="c1"><span>Parteneri și furnizori de servicii: Folosim serviciile altor companii sau persoane fizice
            pentru a presta anumite servicii în numele nostru, cum ar fi hosting-ul pentru acest site, trimiterea de
            mesaje pe email sau plasarea de apeluri telefonice. Ar putea avea acces la informațiile tale personale
            pentru a duce la îndeplinire sarcinile contractuale, ca de exemplu adrese de email, dar din punct de vedere
            contractual nu au voie să folosească informațiile decât pentru ducerea la îndeplinire a contractului
            încheiat cu </span><span class="c5">Furnizorul</span><span class="c0">;</span></li>
        <li class="c1"><span>Transferuri de business: Pe măsură ce business-ul nostru se dezvoltă, am putea să cumpărăm
            sau să vindem active. În tranzacții de acest tip, informațiile despre useri sunt asemenea active. De
            asemenea, dacă </span><span class="c5">Furnizorul </span><span>sau o parte din activele </span><span
            class="c5">Furnizorului</span><span class="c0">&nbsp;va fi achiziționată, datele tale personale ar putea fi
            unul dintre activele tranzacționate;</span></li>
        <li class="c1"><span>Aspecte legale: </span><span class="c5">Furnizorul</span><span>&nbsp;își rezervă dreptul,
            și are dreptul să divulge orice informații despre tine și despre activitatea ta pe acest site, fără acordul
            tău prealabil, în cazul în care </span><span class="c5">Furnizorul</span><span class="c0">&nbsp;este de bună
            credință și crede că acțiunile sunt necesare pentru a: </span></li>
        <li class="c1"><span>(a) proteja și apăra drepturile, proprietatea sau siguranța </span><span
            class="c5">Furnizorului</span><span class="c0">&nbsp;sau a partenerilor săi, a altor utilizatori ai acestui
            site sau publicul larg; </span></li>
        <li class="c1"><span class="c0">(b) întări utilizarea prezentelor termene și condiții care se aplica acestui
            site; </span></li>
        <li class="c1"><span class="c0">(c) răspunde solicitărilor legate de conținutul acestui site care încalcă
            drepturile terțelor părți; </span></li>
        <li class="c1"><span class="c0">(d) răspunde solicitărilor legate de activități suspectate a fi ilegale sau
            dovedite a fi ilegale; </span></li>
        <li class="c1"><span class="c0">(e) răspunde unui audit, investigații sau plângere legată de o amenințare la
            securitatea unei entități;</span></li>
        <li class="c1"><span class="c0">(f) conforma la legislația în vigoare, regulamente, procese sau solicitări ale
            Guvernului.</span></li>
      </ul>
      <p class="c2"><span class="c6 c5">Ce informații solicităm și de ce?</span></p>
      <p class="c2"><span class="c0">Nu vom solicita mai multe informații decât avem nevoie pentru a ne îndeplini scopul
          și nu le vom stoca pentru o perioadă mai mare decât este necesar.</span></p>
      <p class="c2"><span class="c0">Informațiile pe care le solicităm sunt folosite pentru a evalua potrivirea cu rolul
          pentru care ai aplicat. Nu este obligatoriu să ne furnizezi toate datele solicitate, dar acest lucru ar putea
          afecta evaluarea pentru postul pentru care ai aplicat. </span></p>
      <p class="c2"><span class="c6 c5">Ce pași urmăm pentru a proteja informațiile personale dezvăluite</span></p>
      <p class="c2"><span class="c0">Acest site și toată informația pe care o dezvălui pe acest site este colectată,
          depozitată și procesată în România. Permitem accesul la datele personale numai acelor angajați și numai
          furnizorilor de servicii care au nevoie de ele pentru a asigura buna funcționare a acestui site și pentru
          livrarea serviciilor noastre. Am implementat sisteme de securitate fizică, administrativă și tehnică pentru a
          proteja datele personale de accesul neautorizat. Cu toate acestea, pe cât de eficiente sunt sistemele noastre
          de securitate, nici un sistem nu este impenetrabil. Nu putem garanta impenetrabilitatea serviciilor noastre de
          securitate și nici faptul că informația transmisă către noi nu va fi interceptată în momentul în care este
          divulgată.</span></p>
      <p class="c2"><span class="c6 c5">Conform regulamentului 2016/679/UE utilizatorii serviciilor Furnizorului au
          următoarele drepturi:</span></p>
      <ol class="c7 lst-kix_hkrc7y1ghhxn-0 start" start="1">
        <li class="c2 c9"><span class="c5">Dreptul de acces</span><span class="c0">&nbsp;înseamnă ca ai dreptul de a
            obține o confirmare din partea noastră că prelucrăm sau nu datele cu caracter personal care te privesc și,
            în caz afirmativ, acces la datele respective și la informații privind modalitatea în care sunt prelucrate
            datele.</span></li>
        <li class="c2 c9"><span class="c5">Dreptul la portabilitatea datelor</span><span class="c0">&nbsp;se referă la
            dreptul de a primi datele personale într-un format structurat, utilizat în mod curent și care poate fi citit
            automat și la dreptul ca aceste date să fie transmise direct altui operator, dacă acest lucru este fezabil
            din punct de vedere tehnic.</span></li>
        <li class="c2 c9"><span class="c5">Dreptul la opoziție</span><span class="c0">&nbsp;vizează dreptul de a te
            opune prelucrării datelor personale atunci când prelucrarea este necesară pentru îndeplinirea unei sarcini
            care servește unui interes public sau când are în vedere un interes legitim al operatorului. Atunci când
            prelucrarea datelor cu caracter personal are drept scop marketingul direct, ai dreptul de a te opune
            prelucrării în orice moment.</span></li>
        <li class="c2 c9"><span class="c5">Dreptul la rectificare</span><span class="c0">&nbsp;se referă la corectarea,
            fără întârzieri nejustificate, a datelor cu caracter personal inexacte. Rectificarea va fi comunicată
            fiecărui destinatar la care au fost transmise datele, cu excepția cazului în care acest lucru se dovedește
            imposibil sau presupune eforturi disproporționate.</span></li>
        <li class="c2 c9"><span class="c5">Dreptul la ștergerea datelor (“dreptul de a fi uitat”)</span><span
            class="c0">&nbsp;înseamnă că ai dreptul de a solicita să îți ștergem datele cu caracter personal, fără
            întârzieri nejustificate, în cazul în care se aplică unul dintre următoarele motive: acestea nu mai sunt
            necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate; îți retragi
            consimțământul și nu există nici un alt temei juridic pentru prelucrare; te opui prelucrării și nu există
            motive legitime care să prevaleze; datele cu caracter personal au fost prelucrate ilegal; datele cu caracter
            personal trebuie șterse pentru respectarea unei obligații legale; datele cu caracter personal au fost
            colectate în legătură cu oferirea de servicii ale societății informaționale.</span></li>
        <li class="c2 c9"><span class="c5">Dreptul la restricționarea prelucrării</span><span>&nbsp;poate fi exercitat
            în cazul în care persoana contestă exactitatea datelor, pe o perioadă care ne permite verificarea
            corectitudinii datelor; prelucrarea este ilegală, iar persoana se opune ștergerii datelor cu caracter
            personal, solicitând în schimb restricționarea; în cazul în care </span><span
            class="c5">Furnizorul</span><span class="c0">&nbsp;nu mai are nevoie de datele cu caracter personal în
            scopul prelucrării, dar persoana i le solicită pentru constatarea, exercitarea sau apărarea unui drept în
            instanță; în cazul în care persoana s-a opus prelucrării pentru intervalul de timp în care se verifică dacă
            drepturile legitime ale operatorului prevalează asupra celor ale persoanei respective.</span></li>
      </ol>
      <p class="c2"><span class="c0">Toate aceste drepturi pot sa fi exercitate printr-o cerere scrisă, semnată și
          datată, transmisă la sediul nostru sau la adresa de email de mai jos.</span></p>
      <p class="c2"><span class="c6 c5">Întrebări sau nelămuriri?</span></p>
      <p class="c2"><span>Dacă ai orice întrebări, nelămuriri sau plângeri legate de prezenta politică de
          confidențialitate sau condițiile de confidențialitate în general, te rugam să le direcționezi către
        </span><span class="c5">Furnizor</span><span>&nbsp;la adresa de email </span><span class="c8"><a class="c10"
            href="mailto:contact@restaurant-planb.ro">contact@curatenieperfecta.ro</a></span><span class="c0">&nbsp;sau
          folosind datele din pagina de contact.</span></p>
      <p class="c13"><span class="c0"></span></p>
      <p class="c11"><span class="c0"></span></p>
      <p class="c11"><span class="c0"></span></p>
    </div>
  </div>
</section>
