<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE PENTRU LOCUINTE</h1>
        <img src="/assets/images/services/curatenie-la-domiciliu.jpg" alt="curatenie birouri">
      </div><!-- Section Header /- -->
      <p>Facem curatenie perfecta pentru garsoniere, apartamente de 2 camere, apartamente de 3 camere, apartamente de 4
        camere si vile. Preturile pot sa varieze in functie de necesitatile tale. De aceea, estimarea corecta se poate
        face doar dupa o discutie cu unul dintre membrii echipe noastre.
      </p>
      <hr>

      <h2>Curatenie la domiciliu? Nu a fost niciodata mai simplu pentru tine</h2>
      <p>Casa ta este locul in care ar trebui sa te face sa fii cel mai confortabil. Ce bine te simti intr-o locuinta
        curata, ordonata si perfect igienizata, nu-i asa?
        Totusi, in timp ce programul pare sa fie din ce in ce mai aglomerat, casa incepe sa adune praf, grasime intarita
        in bucatarie, murdarie inestetica si un aer tot mai incarcat, aspecte deloc benefice pentru sanatatea ta si a
        familiei tale.
        Este normal ca murdaria sa apara, chiar daca te ocupi personal de asta, mai ales in zonele greu accesibile.
        O firma de curatenie profesionala nu doar ca va curata in profunzime toate spatiile dorite intr-o perioada
        scurta, dar iti va oferi timp de care sa te bucuri asa cum vrei.

      </p>

      <app-service-section></app-service-section>
      <h3>Ce includ servciile de curatenie la domiciliu?</h3>
      
        
          <app-link-services>
            <app-links
              linkTitle="Stergerea amanuntita a prafului de pe mobilier cu lavete micro-fibra si solutii antistatice"
              linkIcon="checked"></app-links>
            <app-links linkTitle="Stergerea tuturor corpurilor de iluminat si a intrerupatoarelor" linkIcon="checked">
            </app-links>
            <app-links linkTitle="Aspirare si spalare profesionala covoare" linkIcon="checked"></app-links>
            <app-links
              linkTitle="Curatat si igienizat bucatarii (faianta, gresie, mobilier, aparatura electrocasnica, chiuvete)"
              linkIcon="checked"></app-links>
            <app-links
              linkTitle="Curatat si igienizat bai (gresie, faianta, mobilier de baie, oglinzi, toalete, cabine de dus)"
              linkIcon="checked"></app-links>
            <app-links linkTitle="Spalat geamuri interioare si exterioare" linkIcon="checked"></app-links>
            <app-links linkTitle="Spalare mecanizata podele (la cerere)" linkIcon="checked">
            </app-links>
            <app-links linkTitle="Spalat canapele textile sau piele (la cerere)" linkIcon="checked"></app-links>
            <app-links linkTitle="Spalat scaune si fotolii textile sau piele (la cerere) " linkIcon="checked">
            </app-links>

          </app-link-services>
       
      


      <h3>Detalile chiar conteaza</h3>

      <p>De fiecare data cand iti curatam locuinta, acordam atentie “micilor detalii”, pe care cele mai multe firme de
        curatenie le omit. Acele lucruri marunte, cum ar fi mutarea (si nu ocolirea) plantelor, a mobilei si a
        obiectelor de decor atunci cand facem curatenie, indreptarea tablourilor dupa curatare. Facem curatenie ca si
        cum ar fi casa noastra, de aceea tinem cont de cele mai mici detalii, iar la final, tu vei avea o locuinta asa
        cum toate ar trebui sa fie - <strong>perfect curata</strong> si <strong>igienizata</strong>.
      </p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
