import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-couch-cleaning',
  templateUrl: './couch-cleaning.component.html',
  styleUrls: ['./couch-cleaning.component.scss']
})
export class CouchCleaningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
