<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATENIE DUPA EVENIMENTE</h1>
        <img src="/assets/images/services/curatenie-dupa-evenimente.jpg" alt="curatenie evenimente">
      </div><!-- Section Header /- -->
      <p>Evenimentele inseamna in primul rand un rulaj numar mare de persoane, care vin, pleaca, arunca pe jos sau in
        cos ambalaje, sticle si doze. Prin urmare, si spatiul necesita o curatare atenta, dar rapida si eficienta, prin
        care locul de desfasurare sa poata fi utilizat din nou in cel mai scurt timp posibil. <strong>De ce este nevoie
          de o
          echipa de profesionisti?</strong>
      </p>
      <hr>

      <h2>Curatenie rapida pentru si mai multa distractie</h2>
      <p>Se spune ca distractia este la ea acasa, dar ce spunem despre curatenie, atunci cand un eveniment se incheie?
        Curatenia este la fel de importanta ca si distractia, mai ales dupa ce mii de oamenii au sarit in sus la
        concertul de aseara sau sute de oameni au ales sa participe la expozitiile organizate de tine. Indiferent ca
        evenimentul tau este o expozitie nationala, un festival de muzica, o conferinta corporativa, eveniment sportiv
        sau concert, echipa noastra de profesionisti te ajuta sa mentii curatenia si cel mai important sa cureti locul
        la timp pentru organizarea urmatorului eveniment.</p>
      <p>In plus, oferim echipe profesioniste, uniforme pentru curatenie la scara mica, medie si mare inainte, in timpul
        si dupa eveniment.</p>
      <app-service-section></app-service-section>
      <h3>Varietate si excelenta in servicii de curatenie zona evenimente</h3>
      <p>Va oferim servicii de curatare specializate pentru evenimente corporate si publice, de interior si in aer liber
        pe tot parcursul anului. Indiferent de dimensiunea evenimentului tau, echipa noastra este aici pentru a te
        ajuta:</p>

      <app-link-services>
        <app-links linkTitle="Pastram spatiul curat inainte, in timpul si dupa eveniment" linkIcon="checked">
        </app-links>
        <app-links linkTitle="Curatam baruri, zone de catering, standuri comerciale" linkIcon="checked"></app-links>
        <app-links linkTitle="Curatam si igienizam toalete" linkIcon="checked"></app-links>
        <app-links linkTitle="Facem curatenie in vestiare" linkIcon="checked"></app-links>
        <app-links linkTitle=" Pregatim locuinta ta inainte de un eveniment, dar mai ales dupa" linkIcon="checked">
        </app-links>
        <app-links linkTitle="Colectaram gunoiului si eliminaram deseurile" linkIcon="checked"></app-links>
      </app-link-services>
      <p>Cere acum o oferta personalizata, iar o echipa se va deplasa si va analiza spatiul pentru a-ti prezenta
        solutiile cele mai potrivite pentru tine si invitatii tai.</p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
