<section>
  <div class="container">
    <div id="contents">
      <style type="text/css">
        .lst-kix_5o2meqj0dfgh-3>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-3, decimal) ". "
        }

        .lst-kix_5o2meqj0dfgh-4>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-4, lower-latin) ". "
        }

        .lst-kix_jqes0hjrw8m-8>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-8
        }

        .lst-kix_5o2meqj0dfgh-2>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-2, lower-roman) ". "
        }

        .lst-kix_5o2meqj0dfgh-6>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-6, decimal) ". "
        }

        .lst-kix_769uvk1q0g6q-0>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_jqes0hjrw8m-1.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-1 0
        }

        .lst-kix_5o2meqj0dfgh-0>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-0, decimal) ". "
        }

        .lst-kix_5o2meqj0dfgh-7>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-7, lower-latin) ". "
        }

        .lst-kix_5o2meqj0dfgh-8>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-8, lower-roman) ". "
        }

        .lst-kix_769uvk1q0g6q-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_769uvk1q0g6q-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_k4ckzs4naqg0-7>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-7
        }

        .lst-kix_5o2meqj0dfgh-1>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-1, lower-latin) ". "
        }

        .lst-kix_769uvk1q0g6q-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_6cij8ndfa6j8-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_6cij8ndfa6j8-8>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_jqes0hjrw8m-7.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-7 0
        }

        ol.lst-kix_5o2meqj0dfgh-4.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-4 0
        }

        .lst-kix_ckf57fuxmive-8>li:before {
          content: "\0025a0  "
        }

        .lst-kix_ckf57fuxmive-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_ckf57fuxmive-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_ckf57fuxmive-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_ckf57fuxmive-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_zda7zej2iymf-4>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_k4ckzs4naqg0-4.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-4 0
        }

        ul.lst-kix_zda7zej2iymf-4 {
          list-style-type: none
        }

        ul.lst-kix_zda7zej2iymf-3 {
          list-style-type: none
        }

        ul.lst-kix_zda7zej2iymf-2 {
          list-style-type: none
        }

        ul.lst-kix_zda7zej2iymf-1 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-7.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-7 0
        }

        ul.lst-kix_zda7zej2iymf-0 {
          list-style-type: none
        }

        .lst-kix_zda7zej2iymf-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_sih2dyox03xe-4>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-4
        }

        .lst-kix_jqes0hjrw8m-6>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-6
        }

        ol.lst-kix_sih2dyox03xe-1.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-1 0
        }

        .lst-kix_zda7zej2iymf-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_zda7zej2iymf-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_zda7zej2iymf-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_5o2meqj0dfgh-5>li:before {
          content: ""counter(lst-ctn-kix_5o2meqj0dfgh-5, lower-roman) ". "
        }

        ol.lst-kix_5o2meqj0dfgh-3.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-3 0
        }

        .lst-kix_sih2dyox03xe-1>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-1, lower-latin) ". "
        }

        .lst-kix_sih2dyox03xe-0>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-0, decimal) ". "
        }

        ol.lst-kix_sih2dyox03xe-6.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-6 0
        }

        ul.lst-kix_zda7zej2iymf-8 {
          list-style-type: none
        }

        .lst-kix_5o2meqj0dfgh-8>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-8
        }

        ul.lst-kix_zda7zej2iymf-7 {
          list-style-type: none
        }

        ul.lst-kix_zda7zej2iymf-6 {
          list-style-type: none
        }

        ul.lst-kix_zda7zej2iymf-5 {
          list-style-type: none
        }

        .lst-kix_5o2meqj0dfgh-1>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-1
        }

        .lst-kix_sih2dyox03xe-0>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-0
        }

        .lst-kix_sih2dyox03xe-6>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-6
        }

        ol.lst-kix_jqes0hjrw8m-2.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-2 0
        }

        .lst-kix_k4ckzs4naqg0-5>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-5
        }

        ol.lst-kix_sih2dyox03xe-3 {
          list-style-type: none
        }

        ol.lst-kix_k4ckzs4naqg0-3.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-3 0
        }

        ol.lst-kix_sih2dyox03xe-4 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-1 {
          list-style-type: none
        }

        .lst-kix_769uvk1q0g6q-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_769uvk1q0g6q-7>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_sih2dyox03xe-2 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-7 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-8 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-5 {
          list-style-type: none
        }

        .lst-kix_769uvk1q0g6q-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_769uvk1q0g6q-8>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_sih2dyox03xe-6 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-0 {
          list-style-type: none
        }

        .lst-kix_769uvk1q0g6q-6>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_5o2meqj0dfgh-8.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-8 0
        }

        .lst-kix_jqes0hjrw8m-2>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-2
        }

        ol.lst-kix_k4ckzs4naqg0-5.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-5 0
        }

        ol.lst-kix_jqes0hjrw8m-8.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-8 0
        }

        .lst-kix_5o2meqj0dfgh-4>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-4
        }

        .lst-kix_ckfyatcz4mjw-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_k4ckzs4naqg0-1>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-1
        }

        .lst-kix_ckfyatcz4mjw-8>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_sih2dyox03xe-0.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-0 0
        }

        ol.lst-kix_jqes0hjrw8m-0.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-0 2
        }

        .lst-kix_8secj0bc4588-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_ckfyatcz4mjw-4>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_k4ckzs4naqg0-8.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-8 0
        }

        .lst-kix_k4ckzs4naqg0-0>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-0
        }

        ul.lst-kix_vyjdh5h7jye-0 {
          list-style-type: none
        }

        .lst-kix_8secj0bc4588-1>li:before {
          content: "\0025cb  "
        }

        ul.lst-kix_vyjdh5h7jye-1 {
          list-style-type: none
        }

        .lst-kix_ckfyatcz4mjw-2>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_vyjdh5h7jye-2 {
          list-style-type: none
        }

        .lst-kix_jqes0hjrw8m-0>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-0
        }

        ul.lst-kix_vyjdh5h7jye-3 {
          list-style-type: none
        }

        .lst-kix_sih2dyox03xe-7>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-7
        }

        ul.lst-kix_vyjdh5h7jye-8 {
          list-style-type: none
        }

        .lst-kix_8secj0bc4588-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_sih2dyox03xe-2>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-2, lower-roman) ". "
        }

        ul.lst-kix_vyjdh5h7jye-4 {
          list-style-type: none
        }

        .lst-kix_8secj0bc4588-3>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_vyjdh5h7jye-5 {
          list-style-type: none
        }

        .lst-kix_ckfyatcz4mjw-0>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_vyjdh5h7jye-6 {
          list-style-type: none
        }

        ul.lst-kix_vyjdh5h7jye-7 {
          list-style-type: none
        }

        .lst-kix_jqes0hjrw8m-0>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-0, decimal) ". "
        }

        .lst-kix_sih2dyox03xe-4>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-4, lower-latin) ". "
        }

        .lst-kix_sih2dyox03xe-6>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-6, decimal) ". "
        }

        .lst-kix_jqes0hjrw8m-2>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-2, lower-roman) ". "
        }

        ol.lst-kix_k4ckzs4naqg0-7.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-7 0
        }

        .lst-kix_jqes0hjrw8m-4>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-4, lower-latin) ". "
        }

        .lst-kix_sih2dyox03xe-8>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-8, lower-roman) ". "
        }

        ol.lst-kix_sih2dyox03xe-2.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-2 0
        }

        ol.lst-kix_5o2meqj0dfgh-6.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-6 0
        }

        .lst-kix_2w84qloehzyt-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_jqes0hjrw8m-8>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-8, lower-roman) ". "
        }

        .lst-kix_zda7zej2iymf-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_ckf57fuxmive-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_jqes0hjrw8m-6>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-6, decimal) ". "
        }

        .lst-kix_k4ckzs4naqg0-2>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-2, lower-roman) ". "
        }

        .lst-kix_2w84qloehzyt-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_k4ckzs4naqg0-0>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-0, decimal) ". "
        }

        .lst-kix_ckf57fuxmive-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_zda7zej2iymf-8>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_k4ckzs4naqg0-6.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-6 0
        }

        ol.lst-kix_jqes0hjrw8m-3 {
          list-style-type: none
        }

        .lst-kix_sih2dyox03xe-2>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-2
        }

        ol.lst-kix_jqes0hjrw8m-2 {
          list-style-type: none
        }

        ol.lst-kix_jqes0hjrw8m-1 {
          list-style-type: none
        }

        ol.lst-kix_jqes0hjrw8m-0 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-3.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-3 0
        }

        .lst-kix_sih2dyox03xe-8>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-8
        }

        .lst-kix_jqes0hjrw8m-1>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-1
        }

        .lst-kix_6cij8ndfa6j8-5>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_jqes0hjrw8m-8 {
          list-style-type: none
        }

        ol.lst-kix_jqes0hjrw8m-7 {
          list-style-type: none
        }

        ol.lst-kix_jqes0hjrw8m-6 {
          list-style-type: none
        }

        .lst-kix_jqes0hjrw8m-7>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-7
        }

        ol.lst-kix_jqes0hjrw8m-5 {
          list-style-type: none
        }

        .lst-kix_5o2meqj0dfgh-3>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-3
        }

        ol.lst-kix_jqes0hjrw8m-4 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-4>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-4, lower-latin) ". "
        }

        .lst-kix_2w84qloehzyt-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_6cij8ndfa6j8-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_6cij8ndfa6j8-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_k4ckzs4naqg0-6>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-6, decimal) ". "
        }

        ul.lst-kix_syksh3dc8tyx-7 {
          list-style-type: none
        }

        ul.lst-kix_syksh3dc8tyx-6 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-5.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-5 0
        }

        ul.lst-kix_syksh3dc8tyx-8 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-8>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-8, lower-roman) ". "
        }

        .lst-kix_2w84qloehzyt-8>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_syksh3dc8tyx-3 {
          list-style-type: none
        }

        ul.lst-kix_syksh3dc8tyx-2 {
          list-style-type: none
        }

        ul.lst-kix_syksh3dc8tyx-5 {
          list-style-type: none
        }

        ul.lst-kix_syksh3dc8tyx-4 {
          list-style-type: none
        }

        .lst-kix_2w84qloehzyt-6>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_syksh3dc8tyx-1 {
          list-style-type: none
        }

        ul.lst-kix_syksh3dc8tyx-0 {
          list-style-type: none
        }

        ol.lst-kix_sih2dyox03xe-4.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-4 0
        }

        ol.lst-kix_5o2meqj0dfgh-1.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-1 0
        }

        ol.lst-kix_jqes0hjrw8m-4.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-4 0
        }

        ul.lst-kix_ckf57fuxmive-6 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-5 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-7 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-8 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-8 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-7 {
          list-style-type: none
        }

        .lst-kix_sih2dyox03xe-3>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-3
        }

        ul.lst-kix_769uvk1q0g6q-1 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-2 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-0 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-0 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-5 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-2 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-6 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-1 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-3 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-4 {
          list-style-type: none
        }

        ul.lst-kix_769uvk1q0g6q-4 {
          list-style-type: none
        }

        ul.lst-kix_ckf57fuxmive-3 {
          list-style-type: none
        }

        .lst-kix_vyjdh5h7jye-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_syksh3dc8tyx-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_vyjdh5h7jye-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_vyjdh5h7jye-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_vyjdh5h7jye-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_syksh3dc8tyx-8>li:before {
          content: "\0025a0  "
        }

        .lst-kix_syksh3dc8tyx-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_syksh3dc8tyx-7>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_5o2meqj0dfgh-7.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-7 0
        }

        ul.lst-kix_6cij8ndfa6j8-8 {
          list-style-type: none
        }

        .lst-kix_syksh3dc8tyx-5>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_6cij8ndfa6j8-4 {
          list-style-type: none
        }

        ul.lst-kix_6cij8ndfa6j8-5 {
          list-style-type: none
        }

        ul.lst-kix_6cij8ndfa6j8-6 {
          list-style-type: none
        }

        .lst-kix_vyjdh5h7jye-8>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_6cij8ndfa6j8-7 {
          list-style-type: none
        }

        ul.lst-kix_6cij8ndfa6j8-0 {
          list-style-type: none
        }

        .lst-kix_vyjdh5h7jye-7>li:before {
          content: "\0025cb  "
        }

        ul.lst-kix_6cij8ndfa6j8-1 {
          list-style-type: none
        }

        ul.lst-kix_6cij8ndfa6j8-2 {
          list-style-type: none
        }

        ul.lst-kix_6cij8ndfa6j8-3 {
          list-style-type: none
        }

        .lst-kix_vyjdh5h7jye-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_sih2dyox03xe-1>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-1
        }

        ol.lst-kix_k4ckzs4naqg0-1.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-1 0
        }

        .lst-kix_vyjdh5h7jye-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_vyjdh5h7jye-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_k4ckzs4naqg0-6>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-6
        }

        .lst-kix_jqes0hjrw8m-5>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-5
        }

        .lst-kix_5o2meqj0dfgh-2>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-2
        }

        .lst-kix_syksh3dc8tyx-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_syksh3dc8tyx-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_syksh3dc8tyx-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_syksh3dc8tyx-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_5o2meqj0dfgh-7>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-7
        }

        ol.lst-kix_5o2meqj0dfgh-2.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-2 0
        }

        ol.lst-kix_sih2dyox03xe-5.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-5 0
        }

        .lst-kix_k4ckzs4naqg0-8>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-8
        }

        ul.lst-kix_ckfyatcz4mjw-8 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-6 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-7 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-2>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-2
        }

        ul.lst-kix_ckfyatcz4mjw-0 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-1 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-4 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-5 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-2 {
          list-style-type: none
        }

        ul.lst-kix_ckfyatcz4mjw-3 {
          list-style-type: none
        }

        .lst-kix_5o2meqj0dfgh-5>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-5
        }

        ul.lst-kix_2w84qloehzyt-1 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-0 {
          list-style-type: none
        }

        .lst-kix_8secj0bc4588-0>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_sih2dyox03xe-8.start {
          counter-reset: lst-ctn-kix_sih2dyox03xe-8 0
        }

        .lst-kix_ckfyatcz4mjw-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_ckfyatcz4mjw-7>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_k4ckzs4naqg0-2.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-2 0
        }

        .lst-kix_8secj0bc4588-8>li:before {
          content: "\0025a0  "
        }

        .lst-kix_8secj0bc4588-6>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_k4ckzs4naqg0-8 {
          list-style-type: none
        }

        ol.lst-kix_jqes0hjrw8m-3.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-3 0
        }

        .lst-kix_ckfyatcz4mjw-3>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_k4ckzs4naqg0-7 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-3>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-3
        }

        ol.lst-kix_k4ckzs4naqg0-6 {
          list-style-type: none
        }

        ol.lst-kix_k4ckzs4naqg0-5 {
          list-style-type: none
        }

        ol.lst-kix_k4ckzs4naqg0-4 {
          list-style-type: none
        }

        .lst-kix_ckfyatcz4mjw-1>li:before {
          content: "\0025cb  "
        }

        ol.lst-kix_k4ckzs4naqg0-3 {
          list-style-type: none
        }

        .lst-kix_8secj0bc4588-2>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_k4ckzs4naqg0-2 {
          list-style-type: none
        }

        ol.lst-kix_k4ckzs4naqg0-1 {
          list-style-type: none
        }

        ol.lst-kix_k4ckzs4naqg0-0 {
          list-style-type: none
        }

        .lst-kix_sih2dyox03xe-3>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-3, decimal) ". "
        }

        ol.lst-kix_5o2meqj0dfgh-0.start {
          counter-reset: lst-ctn-kix_5o2meqj0dfgh-0 0
        }

        .lst-kix_jqes0hjrw8m-3>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-3
        }

        .lst-kix_8secj0bc4588-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_sih2dyox03xe-5>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-5, lower-roman) ". "
        }

        ol.lst-kix_k4ckzs4naqg0-0.start {
          counter-reset: lst-ctn-kix_k4ckzs4naqg0-0 3
        }

        ol.lst-kix_jqes0hjrw8m-6.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-6 0
        }

        .lst-kix_jqes0hjrw8m-1>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-1, lower-latin) ". "
        }

        .lst-kix_k4ckzs4naqg0-4>li {
          counter-increment: lst-ctn-kix_k4ckzs4naqg0-4
        }

        .lst-kix_2w84qloehzyt-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_sih2dyox03xe-7>li:before {
          content: ""counter(lst-ctn-kix_sih2dyox03xe-7, lower-latin) ". "
        }

        .lst-kix_k4ckzs4naqg0-3>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-3, decimal) ". "
        }

        .lst-kix_jqes0hjrw8m-3>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-3, decimal) ". "
        }

        .lst-kix_ckf57fuxmive-0>li:before {
          content: "\0025cf  "
        }

        .lst-kix_zda7zej2iymf-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_zda7zej2iymf-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_jqes0hjrw8m-5>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-5, lower-roman) ". "
        }

        .lst-kix_k4ckzs4naqg0-1>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-1, lower-latin) ". "
        }

        .lst-kix_ckf57fuxmive-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_jqes0hjrw8m-7>li:before {
          content: ""counter(lst-ctn-kix_jqes0hjrw8m-7, lower-latin) ". "
        }

        .lst-kix_sih2dyox03xe-5>li {
          counter-increment: lst-ctn-kix_sih2dyox03xe-5
        }

        ul.lst-kix_8secj0bc4588-2 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-3 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-0 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-1 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-6 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-7 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-4 {
          list-style-type: none
        }

        ul.lst-kix_8secj0bc4588-5 {
          list-style-type: none
        }

        .lst-kix_6cij8ndfa6j8-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_5o2meqj0dfgh-0>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-0
        }

        .lst-kix_jqes0hjrw8m-4>li {
          counter-increment: lst-ctn-kix_jqes0hjrw8m-4
        }

        ol.lst-kix_jqes0hjrw8m-5.start {
          counter-reset: lst-ctn-kix_jqes0hjrw8m-5 0
        }

        ul.lst-kix_8secj0bc4588-8 {
          list-style-type: none
        }

        .lst-kix_5o2meqj0dfgh-6>li {
          counter-increment: lst-ctn-kix_5o2meqj0dfgh-6
        }

        .lst-kix_6cij8ndfa6j8-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_6cij8ndfa6j8-2>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_5o2meqj0dfgh-1 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-5 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-2 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-4 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-3 {
          list-style-type: none
        }

        .lst-kix_2w84qloehzyt-3>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_2w84qloehzyt-3 {
          list-style-type: none
        }

        .lst-kix_2w84qloehzyt-5>li:before {
          content: "\0025a0  "
        }

        ol.lst-kix_5o2meqj0dfgh-4 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-2 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-8 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-5>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-5, lower-roman) ". "
        }

        ul.lst-kix_2w84qloehzyt-7 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-0 {
          list-style-type: none
        }

        ul.lst-kix_2w84qloehzyt-6 {
          list-style-type: none
        }

        .lst-kix_2w84qloehzyt-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_6cij8ndfa6j8-0>li:before {
          content: "\0025cf  "
        }

        ol.lst-kix_5o2meqj0dfgh-5 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-6 {
          list-style-type: none
        }

        ol.lst-kix_5o2meqj0dfgh-7 {
          list-style-type: none
        }

        .lst-kix_k4ckzs4naqg0-7>li:before {
          content: ""counter(lst-ctn-kix_k4ckzs4naqg0-7, lower-latin) ". "
        }

        ol.lst-kix_5o2meqj0dfgh-8 {
          list-style-type: none
        }

        ol {
          margin: 0;
          padding: 0
        }

        table td,
        table th {
          padding: 0
        }

        .c10 {
          -webkit-text-decoration-skip: none;
          color: #000000;
          font-weight: 700;
          text-decoration: underline;
          vertical-align: baseline;
          text-decoration-skip-ink: none;
          font-size: 11pt;
          font-family: "Arial";
          font-style: italic
        }

        .c12 {
          background-color: #ffffff;
          padding-top: 11pt;
          padding-bottom: 11pt;
          line-height: 1.4;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .c19 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 18pt;
          font-family: "Arial";
          font-style: normal
        }

        .c4 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 11pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c6 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: italic
        }

        .c1 {
          color: #000000;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c0 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: left;
          height: 11pt
        }

        .c3 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c11 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 34pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c7 {
          background-color: #ffffff;
          padding-top: 11pt;
          padding-bottom: 11pt;
          line-height: 1.4;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c8 {
          padding-top: 0pt;
          padding-bottom: 10pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c15 {
          text-decoration-skip-ink: none;
          -webkit-text-decoration-skip: none;
          color: #1155cc;
          text-decoration: underline
        }

        .c17 {
          background-color: #ffffff;
          max-width: 451.4pt;
          padding: 72pt 72pt 72pt 72pt
        }

        .c5 {
          margin-left: 36pt;
          padding-left: 0pt
        }

        .c13 {
          margin-left: 53pt;
          padding-left: 0pt
        }

        .c9 {
          color: inherit;
          text-decoration: inherit
        }

        .c2 {
          padding: 0;
          margin: 0
        }

        .c14 {
          font-style: italic
        }

        .c16 {
          font-weight: 700
        }

        .c18 {
          height: 11pt
        }

        .title {
          padding-top: 0pt;
          color: #000000;
          font-size: 26pt;
          padding-bottom: 3pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .subtitle {
          padding-top: 0pt;
          color: #666666;
          font-size: 15pt;
          padding-bottom: 16pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        li {
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        p {
          margin: 0;
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        h1 {
          padding-top: 20pt;
          color: #000000;
          font-size: 20pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h2 {
          padding-top: 18pt;
          color: #000000;
          font-size: 16pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h3 {
          padding-top: 16pt;
          color: #434343;
          font-size: 14pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h4 {
          padding-top: 14pt;
          color: #666666;
          font-size: 12pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h5 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h6 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          font-style: italic;
          orphans: 2;
          widows: 2;
          text-align: left
        }

      </style>
      <p class="c12"><span class="c19">Politica de Cookie-uri</span></p>
      <p class="c12"><span class="c3">1. &nbsp; INTRODUCERE</span></p>
      <p class="c4"><span class="c3">Această politică privind utilizarea cookie-urilor este concepută pentru a vă
          informa în legatură cu practicile noastre în ceea ce privește colectarea de informații de la dvs. atunci când
          vizitati site-ul nostru, prin intermediul cookie-urilor și a altor tehnologii de urmărire, însă cu respectarea
          dreptului dvs. la protecția datelor cu caracter personal care vă privesc.</span></p>
      <p class="c4"><span class="c3">Cookie-urile noastre nu rețin date cu caracter personal, cum ar fi numele sau
          detaliile bancare. </span></p>
      <p class="c4"><span class="c3">Confidențialitatea informatiilor este o responsabilitate permanentă si, prin
          urmare, ne asumăm că vom actualiza periodic acest document de informare pe măsură ce vom întreprinde noi
          practici privind datele cu caracter personal sau vom adopta noi politici de confidențialitate. În ceea ce
          privește prelucrarea datelor bazate pe consimțământ, vă vom notifica orice modificare și vom solicita
          consimțământul dvs. suplimentar.</span></p>
      <p class="c4"><span class="c3">Precizăm că nu vom solicita ori primi informații, de orice natura, de la copiii sub
          16 ani.</span></p>
      <p class="c4"><span class="c3">Informațiile de mai jos aduc la cunoștința dvs. mai multe detalii despre plasarea,
          utilizarea si administrarea cookie-urilor utilizate de site-ul www.curatenieperfecta.ro. De asemenea, sunt
          prezente și câteva linkuri utile legate de acest subiect.</span></p>
      <p class="c4"><span>Site-ul www.curatenieperfecta.ro este operat de societatea </span><span class="c16">CLEANSPOT
          TEAM SRL, </span><span>denumit in continuare </span><span class="c16">Furnizor, </span><span class="c3">cu
          sediul social în cu sediul in Str. Liviu Rebreanu, Nr.7, Bl.51, Sc.C, Et.7, Ap.118, Sector 3 Bucuresti,
          înregistrată la Registrul Comerțului sub J40/13127/2016 | având cod fiscal RO36603630, ce utilizează
          cookie-uri atât proprii, cât și de la terți, pentru a furniza vizitatorilor o experiență mai buna de navigare
          și servicii adaptate nevoilor și interesului vizitatorilor și clienților săi. </span></p>
      <p class="c7"><span class="c3">2. &nbsp; CONTEXTUL LEGISLATIV</span></p>
      <p class="c4"><span class="c3">Conform cerințelor Directivei (UE) 2002/58/EC („Directiva E-Privacy”) și a
          Regulamentului (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
          caracter personal și privind libera circulație a acestor date, avem obligația de a administra în condiții de
          siguranță și numai pentru scopurile specificate, datele personale pe care dvs. ni le furnizați prin utilizarea
          site-ului nostru în acord cu prevederile Regulamentului GDPR și a Directivei E-Privacy.</span></p>
      <p class="c4"><span class="c3">In special, Regulamentul GDPR ne obligă, în primul rând, să oferim posibilitatea ca
          utilizatorul să-și exprime sau, dupa caz, să-și retragă oricând consimțământul pentru prelucrarea datelor sale
          cu caracter personal, să rectifice conținutul datelor cu caracter personal sau să solicite transferul datelor
          cu caracter personal către un alt operator, iar, în al doilea rând, să informăm utilizatorul despre conținutul
          cookie-urilor utilizate de site-ul nostru, ce categorii de date personale ale utilizatorului sunt prelucrate
          de cookie-urile noastre, ce facem cu informațiile obținute de cookie-uri și unde stocăm respectivele
          informații, oferirea unui nivel de securitate ridicat asupra informațiilor colectate de cookie-uri,
          posibilitatea utilizatorului de a bloca oricând rularea oricărui sau a tuturor cookie-urilor de pe site-ul
          nostru, precum și stergerea informațiilor colectate de cookie-uri.</span></p>
      <p class="c4"><span class="c3">Pentru mai multe detalii privind conținutul acestor norme juridice, vă rugăm să
          accesați următoarele link-uri:</span></p>
      <ul class="c2 lst-kix_ckfyatcz4mjw-0 start">
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/RO/TXT/?uri%3Dcelex%253A32002L0058&amp;sa=D&amp;ust=1588163459873000">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=celex%3A32002L0058</a></span>
        </li>
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/RO/TXT/?uri%3DCELEX%253A32016R0679&amp;sa=D&amp;ust=1588163459874000">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679</a></span>
        </li>
      </ul>
      <p class="c7"><span class="c3">3. &nbsp; DREPTURILE DUMNEAVOASTRA</span></p>
      <p class="c4"><span>Regulamentul GDPR oferă anumite drepturi persoanelor vizate. Aveți dreptul de a accesa și de a
          rectifica (corecta) înregistrarea datelor dvs. personale prelucrate de noi dacă acestea sunt inexacte. Ne
          puteți solicita să ștergem aceste date sau să încetam prelucrarea acestora, sub rezerva anumitor excepții. De
          asemenea, ne puteți solicita încetarea utilizării datelor dvs. în scopuri de marketing direct. Aveți dreptul
          să depuneți o plângere la autoritatea de protecție a datelor dacă aveți îngrijorări cu privire la modul în
          care prelucrăm datele dvs. personale. Când este fezabil din punct de vedere tehnic, vom putea – la cererea
          dvs. – să vă furnizăm datele personale sau să le transmitem direct unui alt operator. Dacă accesul nu poate fi
          furnizat într-un interval de timp rezonabil, vă vom furniza o dată la care informațiile vor fi transmise.
          Puteți solicita, de asemenea, informații suplimentare despre: scopul prelucrării, categoriile de date cu
          caracter personal vizate, care a fost sursa informațiilor și cât timp vor fi stocate. Dacă doriți să executați
          oricare dintre aceste drepturi, vă rugăm să ne contactati la adresa </span><span class="c15"><a class="c9"
            href="mailto:contact@restaurant-planb.ro">contact@curatenieperfecta.ro</a></span><span
          class="c3">&nbsp;oricând.</span></p>
      <p class="c4"><span class="c1">AVERTIZARE! Vă rugăm să vă exersați în mod rațional drepturile și să rețineti că
          abuzul de drepturi vă poate atrage răspunderea.</span></p>
      <p class="c4"><span class="c3">Datele de contact ale Autorității Naționale de Protectie a Datelor cu caracter
          personal sunt următoarele:</span></p>
      <p class="c4"><span class="c10">Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter
          Personal</span></p>
      <ul class="c2 lst-kix_769uvk1q0g6q-0 start">
        <li class="c4 c5"><span class="c6">Adresa: Bucuresti, b-dul G-ral. Gheorghe Magheru nr. 28-30, sector 1, cod
            postal 010336</span></li>
        <li class="c4 c5"><span class="c14">Pagina de internet:</span><span class="c14"><a class="c9"
              href="https://www.google.com/url?q=http://www.dataprotection.ro/&amp;sa=D&amp;ust=1588163459878000">http://www.dataprotection.ro</a></span>
        </li>
        <li class="c4 c5"><span class="c6">Adresa de e-mail: anspdcp@dataprotection.ro</span></li>
        <li class="c4 c5"><span class="c6">Telefon:+40.318.059.211; +40.318.059.212</span></li>
        <li class="c4 c5"><span class="c6">Fax: +40.318.059.602</span></li>
      </ul>
      <p class="c7"><span class="c3">4. &nbsp; RESPONSABILITATEA PRELUCRĂRII DATELOR CU CARACTER PERSONAL</span></p>
      <p class="c4"><span class="c3">Am luat toate măsurile în ceea ce privește toate aspectele legate de protecția
          datelor cu caracter personal, iar dvs. ne puteți contacta cu privire la toate chestiunile legate de
          prelucrarea datelor dvs. și la exercitarea drepturilor dvs. potrivit dispozițiilor legale aplicabile, în
          special dacă aveți întrebări sau nelămuriri cu privire la modul în care noi prelucrăm datele dvs. cu caracter
          personal. </span></p>
      <p class="c4"><span>Adresa de e-mail la care ne puteți contacta este: </span><span class="c15"><a class="c9"
            href="mailto:contact@restaurant-planb.ro">contact@curatenieperfecta.ro</a></span><span
          class="c3">&nbsp;</span></p>
      <p class="c7"><span class="c3">5. &nbsp; CONSIMȚĂMÂNTUL DVS. PENTRU UTILIZAREA COOKIE-URILOR</span></p>
      <p class="c4"><span class="c3">Odată ce ați accesat site-ul nostru veți fi întâmpinat, in partea de jos a paginii,
          de un mesaj prin care vi se va cere consimțământul în acord cu dispozițiile legale aplicabile cu privire la
          categoriile de cookie-uri pe care veți fi sau nu de acord să le folosim pe durata vizitei dvs. pe site-ul
          nostru. De asemenea, este important de reținut că prin consimțământul dat în sensul utilizarii
          cookie-ului/urilor respectiv/e veți fi de acord și cu stocarea în condiții de siguranță a informațiilor
          colectate de cookie-ul/-urile cu care ați fost de acord.</span></p>
      <p class="c4"><span class="c3">Până la momentul la care procesul de accept/refuz pentru utilizarea cookie-urilor
          nu este finalizat niciun cookie nu va fi utilizat de site-ul nostru, cu excepția cookie-urilor în lipsa cărora
          site-ul nu poate funcționa.</span></p>
      <p class="c4"><span class="c3">Precizăm că oricând veți avea posibilitatea să blocați rularea cookie-urilor, dar
          și posibilitatea de a șterge informațiile colectate de cookie-uri.</span></p>
      <p class="c7"><span class="c3">6. &nbsp; CE ESTE UN “COOKIE”?</span></p>
      <p class="c4"><span>Un “Internet Cookie” (termen cunoscut și ca “browser cookie” sau “HTTP cookie” sau pur și
          simplu “cookie”) este un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe
          computerul, terminalul mobil sau alte echipamente ale utilizatorului de pe care se acceseaza acest site (mai
          multe detalii pe </span><span><a class="c9"
            href="https://www.google.com/url?q=https://ro.wikipedia.org/wiki/Cookie&amp;sa=D&amp;ust=1588163459883000">https://ro.wikipedia.org/wiki/Cookie</a></span><span
          class="c3">).</span></p>
      <p class="c4"><span class="c3">Cookie-ul este instalat prin solicitarea emisa de catre un web-server unui browser
          (ex: Internet Explorer, Chrome, Firefox) și este complet “pasiv” adică nu conține programe software, viruși
          sau spyware și nu poate accesa informațiile de pe hard drive-ul utilizatorului.</span></p>
      <p class="c4"><span class="c3">Un cookie este format din 2 părți: numele și conținutul sau valoarea cookie-ului.
          Mai mult, durata de existență a unui cookie este determinată, iar tehnic doar web-serverul care a trimis
          cookie-ul îl poate accesa din nou în momentul în care un utilizator se întoarce pe website-ul asociat
          web-serverului respectiv.</span></p>
      <p class="c4"><span class="c1">Cookie-urile în sine nu solicită informații cu caracter personal pentru a putea fi
          utilizate și, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.</span></p>
      <p class="c4"><span class="c3">Există 2 categorii mari de cookie-uri:</span></p>
      <ol class="c2 lst-kix_sih2dyox03xe-0 start" start="1">
        <li class="c4 c5"><span class="c16">Cookie-uri de sesiune</span><span class="c3">&nbsp;– acestea sunt stocate
            temporar în dosarul de cookie-uri al browserului web pentru ca acesta să le memoreze până când utilizatorul
            iese de pe site-ul respectiv sau închide fereastra browserului.</span></li>
        <li class="c4 c5"><span class="c16">Cookie-uri persistente</span><span class="c3">&nbsp;– acestea sunt stocate
            pe hard drive-ul unui computer sau echipament pentru o durata configurata de website-ul care l-a plasat.
            Acest tip de cookie-uri pot fi șterse de utilizator în orice moment prin intermediul setărilor
            browserului.</span></li>
      </ol>
      <p class="c7"><span class="c3">7. &nbsp; CARE SUNT AVANTAJELE COOKIE-URILOR?</span></p>
      <p class="c4"><span class="c3">Un cookie conține informații care fac legătura între un web-browser (browser-ul pe
          care îl folosiți dvs., spre exemplu Internet Explorer, Google Chrome, Safari, Mozilla Firefox, etc.) și un
          web-server anume, mai precis serverul care găzduiește site-ul nostru. Dacă un browser accesează acel
          web-server din nou, acesta poate citi informația deja stocată și reacționa în consecință. Cookie-urile asigură
          utilizatorilor o experiență plăcuta de navigare și susțin eforturile multor websiteuri pentru a oferi servicii
          confortabile utilizatorillor: ex. Preferințele în materie de confidențialitate online, opțiunile privind limba
          site-ului, coșuri de cumpărături sau publicitate relevantă.</span></p>
      <p class="c7"><span class="c3">8. &nbsp; CARE ESTE DURATA DE VIAȚĂ A UNUI COOKIE?</span></p>
      <p class="c4"><span class="c3">Cookie-urile sunt administrate de web-servere. Durata de viață a unui cookie poate
          varia semnificativ, depinzând de scopul pentru care este plasat. Unele cookie-uri sunt folosite exclusiv
          pentru o singură sesiune (cookie-urile de sesiune) și nu mai sunt reținute odată ce utilizatorul părăsește
          website-ul și unele cookie-uri sunt reținute și refolosite de fiecare dată când utilizatorul revine pe acel
          website (cookie-urile persistente). Cu toate acestea, cookie-urile pot fi șterse de un utilizator în orice
          moment.</span></p>
      <p class="c7"><span class="c3">9. &nbsp; CUM FOLOSIM NOI COOKIE-URILE PE ACEST SITE</span></p>
      <p class="c4"><span class="c3">Am clasificat cookie-urile noastre în următoarele categorii, pentru a vă fi mai
          ușor să întelegeți de ce avem nevoie de ele. </span></p>
      <p class="c8"><span class="c1">Ce tip de cookie-uri sunt folosite pe website-ul www.curatenieperfecta.ro: </span>
      </p>
      <p class="c8"><span class="c1">Cookie-urile strict necesare </span></p>
      <p class="c8"><span class="c3">Acestea sunt cookie-uri esențiale care vă permit să navigați pe site-ul nostru și
          să folosiți caracteristicile sale. Aceste cookie-uri nu colectează informații despre dumneavoastră care ar
          putea fi folosite pentru publicitate sau pentru a înregistra activitatea pe internet. </span></p>
      <p class="c8"><span class="c1">Cookie-urile de statistici</span></p>
      <p class="c8"><span class="c3">Acestea colectează informații despre modul în care folosiți website-ul, care sunt
          paginile vizualizate cel mai des și dacă întâmpinați mesaje de eroare. Acestea nu colectează informații care
          vă pot identifica, toate datele fiind anonime. Cookie-urile de performanță sunt folosite doar pentru a
          îmbunătăți modul în care funcționeaza site-ul. </span></p>
      <p class="c8"><span class="c1">Cookie-uri de preferinte</span></p>
      <p class="c8"><span class="c3">Acestea înregistrează alegerile pe care le faceți (cum ar fi limba preferata) și vă
          oferă caracteristici de utilizare personalizate. Ele pot înregistra modificările pe care le-ați făcut pe site.
          Informațiile pe care le colectează pot fi făcute anonime. Ele se refera strict la website-ul nostru și nu pot
          urmări alte website-uri pe care le vizitați. </span></p>
      <p class="c8"><span class="c1">Cookie-urile de marketing</span></p>
      <p class="c8"><span class="c3">Acestea vă furnizează anunțuri sau mesaje relevante în conformitate cu interesele
          dumneavoastră. Uneori cookie-urile de marketing sunt legate de alte site-uri, cum ar fi Facebook. </span></p>
      <p class="c8"><span class="c3">Cele patru categorii de cookie-uri &nbsp;sunt clasificate fie ca temporare
          (cookie-uri de “sesiune”) sau pe termen mai lung (“cookie-uri permanente”). </span></p>
      <p class="c8"><span class="c3">Cookie-urile de “Sesiune” înregistrează acțiunile dumneavoastră într-o singură
          sesiune de navigare. Această “sesiune” începe atunci când pagina este deschisă și se termină atunci când este
          închisă. Atunci cookie-urile sunt șterse pentru totdeauna. </span></p>
      <p class="c8"><span class="c3">Cookie-urile “Permanente” sunt cele care rămân pe telefonul sau computerul
          dumneavoastră pentru o anumită perioadă de timp. Ele sunt activate automat atunci când vizitați din nou un
          anumit site. </span></p>
      <p class="c8"><span class="c3">O altă clasificare între cookie-uri se face în funcție de sursa acestora:
          cookie-uri “primare” sau &nbsp;cookie-uri “terțe părți”. </span></p>
      <p class="c8"><span class="c3">Cookie-urile “primare” sunt create de către site-ul www.curatenieperfecta.ro pe
          care îl vizitați, iar cele “terțe părți” sunt create de către un alt site decât cel vizitat. Site-ul
          www.curatenieperfecta.ro permite cookie-uri terțe-părți doar din partea site-urilor aprobate de către
          companie. Ștergerea cookie-urilor În cazul în care nu sunteți mulțumit cu utilizarea de cookie-uri pe acest
          website, le puteți șterge cu ușurință din browser-ul dumneavoastră. De asemenea, puteți seta browser-ul
          &nbsp;să blocheze cookie-urile sau să afișeze o notificare de avertizare înainte ca un cookie să fie stocat pe
          calculatorul dumneavoastră. </span></p>
      <p class="c7"><span class="c3">10.TIPURI DE COOKIE-URI PE CARE LE FOLOSIM:</span></p>
      <ul class="c2 lst-kix_zda7zej2iymf-0 start">
        <li class="c11 c13"><span class="c3">cookie-uri pentru colectare date Google Ads si Google Display</span></li>
        <li class="c11 c13"><span class="c3">cookie-uri pentru colectare date Facebook Ads</span></li>
        <li class="c11 c13"><span class="c3">cookie-uri pentru colectare date Google Analytics</span></li>
      </ul>
      <p class="c4"><span class="c3">Aceste cookies-uri sunt folosite pentru buna funcționare a site-ului, ele fac parte
          din grupa “obligatorie” și nu pot fi oprite, ele sunt valabile pe întreaga perioadă a sesiunii.</span></p>
      <p class="c7"><span class="c3">11.CE SUNT COOKIE-URILE PLASATE DE TERȚI?</span></p>
      <p class="c4"><span class="c3">Cookie-uri plasate de terți („third party cookies”) sunt plasate de un alt website
          decât cel pe care îl vizitează utilizatorul la momentul respectiv și ar putea fi folosite în mod anonim pentru
          a memora istoricul de navigare al unui utilizator, astfel încât acestuia să-i fie livrată publicitate cât mai
          relevantă. Acest tip de cookie-uri pot fi blocate de un utilizator prin intermediul setărilor
          browser-ului.</span></p>
      <p class="c4"><span class="c3">Precizăm că nu avem control și nu suntem responsabili pentru politicile și
          practicile de confidențialitate ale unor terțe părți, însă terțele părți se obligă să respecte legea in
          vigoare și politicile de confidențialitate ale deținatorului acestui site.</span></p>
      <p class="c7"><span class="c3">12.CUM PUTEȚI OPRI COOKIE-URILE?</span></p>
      <p class="c4"><span class="c3">Dezactivarea și refuzul de a primi cookie-uri pot face anumite site-uri
          impracticabile sau dificil de vizitat și folosit. De asemenea, refuzul de a accepta cookie-uri nu înseamnă că
          nu veți mai primi/vedea publicitate online, ci doar ca aceasta nu se va mai potrivi nevoilor
          dumneavoastra.</span></p>
      <p class="c4"><span class="c3">Este posibilă setarea din browser pentru ca aceste cookie-uri să nu mai fie
          acceptate sau puteți seta browserul să accepte cookie-uri de la un site anume.</span></p>
      <p class="c4"><span class="c3">Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor.
          Aceste setări se găsesc de regula în “opțiuni” sau în meniul de “preferințe” al browserului dvs.</span></p>
      <p class="c4"><span class="c3">Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare, altfel
          puteți folosi opțiunea “ajutor” a browserului pentru mai multe detalii.</span></p>
      <ul class="c2 lst-kix_8secj0bc4588-0 start">
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://support.microsoft.com/en-us/kb/196955&amp;sa=D&amp;ust=1588163459895000">Cookie
              settings in Internet Explorer</a></span></li>
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;ust=1588163459896000">Cookie
              settings in Chrome</a></span></li>
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale%3Den-US%26redirectslug%3DCookies&amp;sa=D&amp;ust=1588163459897000">Cookie
              settings in Firefox</a></span></li>
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://support.apple.com/kb/PH5042?locale%3Den_US&amp;sa=D&amp;ust=1588163459897000">Cookie
              settings in Safari</a></span></li>
      </ul>
      <p class="c4"><span class="c3">Pentru setările cookie-urilor generate de terți, puteți consulta și site-ul:</span>
      </p>
      <ul class="c2 lst-kix_6cij8ndfa6j8-0 start">
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=http://www.youronlinechoices.com/ro/&amp;sa=D&amp;ust=1588163459898000">http://www.youronlinechoices.com/ro/</a></span>
        </li>
      </ul>
      <p class="c4"><span class="c1">Linkuri utile</span></p>
      <p class="c4"><span class="c3">Dacă doriți să aflați mai multe informații despre cookie-uri și la ce sunt
          utilizate, recomandăm următoarele linkuri:</span></p>
      <ul class="c2 lst-kix_2w84qloehzyt-0 start">
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;ust=1588163459900000">All
              About Cookies</a></span></li>
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=https://www.microsoft.com/info/cookies.mspx&amp;sa=D&amp;ust=1588163459900000">Microsoft
              Cookies guide</a></span></li>
      </ul>
      <p class="c4"><span class="c3">Pentru a opta să nu mai fiți urmărit de Google Analytics te rugăm să
          vizitezi:</span></p>
      <ul class="c2 lst-kix_vyjdh5h7jye-0 start">
        <li class="c4 c5"><span><a class="c9"
              href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1588163459901000">http://tools.google.com/dlpage/gaoptout</a></span><span
            class="c3">.</span></li>
      </ul>
      <p class="c7"><span class="c3">13.STOCAREA INFORMAȚIILOR CAPTATE DE COOKIE-URI</span></p>
      <p class="c4"><span class="c3">Informațiile obținute de cookie-uri sunt păstrate în condiții de siguranță ridicată
          de web-server, respectiv serverul care gazduiește site-ul nostru.</span></p>
      <p class="c4"><span class="c3">Vă asigurăm că web-serverul nostru trebuie să se conformeze unor cerințe de
          securitate ridicată în ceea ce privește informațiile colectate prin cookie-uri, fiind obligat să respecte cu
          strictețe obligațiile impuse de legea aplicabilă.</span></p>
      <p class="c4"><span class="c3">Informațiile pe care le colectăm despre dvs. prin accesarea web-site-ului nostru nu
          vor fi partajate cu terțe părți.</span></p>
      <p class="c7"><span class="c3">14.SECURITATEA DATELOR PERSONALE</span></p>
      <p class="c4"><span class="c3">Pentru a vă proteja confidențialitatea datelor și a informațiilor personale pe care
          le transmiteți prin utilizarea acestui site web, vom menține garanții fizice, tehnice și
          administrative.</span></p>
      <p class="c4"><span class="c3">Restricționăm accesul la datele dvs. personale doar acelor angajați care trebuie să
          știe acele informații pentru a vă oferi beneficii sau servicii. </span></p>
      <p class="c4"><span class="c3">Dacă apare o scurgere de date cu caracter personal, vom face totul pentru a o
          elimina și pentru a evalua un nivel de risc legat de scurgere în conformitate cu politica noastră privind
          încălcarea datelor cu caracter personal. In cazul în care se constată că scurgerea poate duce la vătămări
          fizice, materiale sau nemateriale pentru dvs. (de exemplu, discriminare, furt de identitate, fraudă sau
          pierdere financiară), vă vom contacta fără întârzieri nejustificate, cu excepția cazului în care legea prevede
          altfel. </span></p>
      <p class="c4"><span class="c3">15.ASPECTE DE SECURITATE LEGATE DE COOKIE-URI</span></p>
      <p class="c4"><span class="c3">Cookieurile NU sunt viruși! Ele folosesc formate tip plain text. Nu sunt alcătuite
          din bucăți de cod așa că nu pot fi executate nici nu pot auto-rula. In consecință, nu se pot duplica sau
          replica pe alte rețele pentru a se rula sau replica din nou. Deoarece nu pot îndeplini aceste funcții, nu pot
          fi considerate viruși.</span></p>
      <p class="c4"><span class="c3">Cookie-urile pot fi totuși folosite pentru scopuri negative. Deoarece stochează
          informații despre preferințele și istoricul de navigare al utilizatorilor, atât pe un anume site cât și pe mai
          multe alte siteuri, cookie-urile pot fi folosite ca o formă de Spyware. Multe produse anti-spyware sunt
          conștiente de acest fapt și în mod constant marchează cookie-urile pentru a fi șterse în cadrul procedurilor
          de ștergere/scanare anti-virus/anti-spyware.</span></p>
      <p class="c4"><span class="c3">In general, browserele au integrate setări de confidențialitate care furnizează
          diferite nivele de acceptare a cookie-urilor, perioada de valabilitate și ștergere automată după ce
          utilizatorul a vizitat un anumit site.</span></p>
      <p class="c4"><span class="c3">Deoarece protecția identității este foarte valoroasă și reprezintă dreptul fiecărui
          utilizator de internet, este indicat să se știe ce eventuale probleme pot crea cookie-urile. Pentru că prin
          intermediul lor se transmit în mod constant în ambele sensuri informații între browser și website, dacă un
          atacator sau persoană neautorizată intervine în parcursul de transmitere a datelor, informațiile conținute de
          cookie pot fi interceptate. Deși foarte rar, acest lucru se poate întâmpla dacă browserul se conectează la
          server folosind o rețea necriptată (ex: o retea WiFi nesecurizată).</span></p>
      <p class="c7"><span class="c3">16.MODIFICĂRI ALE POLITICII PRIVIND COOKIE-URILE</span></p>
      <p class="c4"><span class="c3">Este posibil să actualizăm din când în când acest document de informare prin
          publicarea unei noi versiuni pe acest site web.</span></p>
      <p class="c4"><span class="c3">Având în vedere că legile privind protecția datelor cu caracter personal,
          interpretarile organelor de stat, recomandările autorităților de supraveghere se schimbă și se imbunătățesc
          periodic, se așteaptă ca această notificare privind confidențialitatea și condițiile de utilizare să se
          schimbe și ea. Ne rezervăm dreptul de a modifica în orice moment, din orice motiv și fără notificare acest
          document de informare. Orice modificare a politicii noastre de confidențialitate va fi afișată pe această
          pagină, astfel încât să fiți conștienți de politicile noastre. Dispozițiile conținute în prezentul document
          înlocuiesc toate anunțurile sau declarațiile anterioare privind practicile noastre de
          confidențialitate.</span></p>
      <p class="c7"><span class="c3">17. SFATURI PENTRU O NAVIGARE SIGURĂ ȘI RESPONSABILĂ, BAZATĂ PE COOKIE-URI</span>
      </p>
      <p class="c4"><span class="c3">Datorită flexibilității lor și a faptului că majoritatea dintre cele mai vizitate
          site-uri și cele mai mari folosesc cookie-uri, acestea sunt aproape inevitabile. Dezactivarea cookie-urilor nu
          va permite accesul utilizatorului pe site-urile cele mai răspândite și utilizate printre care Youtube, Gmail,
          Yahoo și altele.</span></p>
      <p class="c4"><span class="c3">Iată câteva sfaturi care vă pot asigura însă că navigați fără griji cu ajutorul
          cookie-urilor:</span></p>
      <ul class="c2 lst-kix_syksh3dc8tyx-0 start">
        <li class="c4 c5"><span class="c3">Particularizați-vă setările browserului în ceea ce privește cookie-urile
            pentru a reflecta un nivel confortabil pentru dvs. al securității utilizării cookie-urilor.</span></li>
        <li class="c5 c11"><span class="c3">Dacă nu vă deranjează cookie-urile și sunteți singura persoană care
            utilizează computerul, puteți seta termene lungi de expirare pentru stocarea istoricului de navigare și al
            datelor personale de acces.</span></li>
        <li class="c11 c5"><span class="c3">Dacă împărțiți accesul la calculator, puteți lua în considerare setarea
            browserului pentru a șterge datele individuale de navigare de fiecare dată când închideti browserul. Aceasta
            este o varianta de a accesa site-urile care plasează cookieuri și de a șterge orice informație de vizitare
            la închiderea sesiunii de navigare.</span></li>
        <li class="c11 c5"><span class="c3">Instalați-vă și updatați-vă constant aplicații antispyware.</span></li>
      </ul>
      <p class="c4"><span class="c3">Multe dintre aplicațiile de detectare și prevenire a spyware-ului includ detectarea
          atacurilor pe site-uri. Astfel, împiedica browserul de la a accesa website-uri care ar putea să exploateze
          vulnerabilitățile browserului sau să descarce software periculos. Asigurați-vă că aveți browserul mereu
          updatat. Multe dintre atacurile bazate pe cookies se realizează exploatând punctele slabe ale versiunilor
          vechi ale browserelor.</span></p>
      <p class="c4"><span class="c3">Cookie-urile sunt pretutindeni și nu pot fi evitate dacă doriți să vă bucurați de
          acces pe cele mai bune și cele mai mari site-uri de pe Internet – locale sau internaționale. Cu o întelegere
          clară a modului lor de operare și a beneficiilor pe care le aduc, puteți lua măsurile necesare de securitate
          astel încât să puteți naviga cu încredere pe internet.</span></p>
      <p class="c7"><span class="c3">18.ÎNTREBĂRI, NELĂMURIRI, RECLAMAȚII</span></p>
      <p class="c4"><span>Dacă aveți întrebări cu privire la informațiile dvs. personale sau dacă doriți să vă
          exercitați drepturile sau aveți nelămuriri sau reclamații, vă rugăm să ne contactați prin trimiterea unui
          e-mail la adresa </span><span class="c15"><a class="c9"
            href="mailto:contact@restaurant-planb.ro">contact@curatenieperfecta.ro</a></span><span class="c3">&nbsp;sau
          telefonic la 0747.171.409</span></p>
      <p class="c4 c18"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c0"><span class="c3"></span></p>
      <p class="c8 c18"><span class="c3"></span></p>
      <p class="c8"><span class="c3">Puteti citi mai multe despre cookie-urile utilizate pe acest website in Politica
          cookies. Tot in pagina Politica cookies va puteti schimba preferintele de cookie-uri sau va puteti retrage
          consimtamantul. </span></p>
      <p class="c0"><span class="c3"></span></p>
    </div>
  </div>
</section>
