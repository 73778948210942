<!-- Service Section -->
<div id="service-section" class="service-section ow-section services-style2">
  <!-- container -->
  <div class="container">
    <app-menu-services></app-menu-services>
    <!-- col-md-9 -->
    <div class="col-md-9 col-sm-8 services-content-area">
      <!-- Section Header -->
      <div class="section-header">
        <h1>CURATARE MOCHETA</h1>
        <img src="/assets/images/services/curatare-mocheta.jpg" alt="curatare mocheta">
      </div><!-- Section Header /- -->
      <p>Stiati ca <span class="green-highlight">peste 70% </span> din praful dintr-o incapere este stocat in
        profunzimea mochetelor? In timp,praful din casa
        creste riscul dezvoltarii unor afectiuni alergice, precum astm alergic, rinita alergica sau dermatita atopica.
        Echipa noastra se deplaseaza la domiciliu pentru a efectua servicii profesionale de curatare mocheta si covor,
        oriunde in Bucuresti si Ilfov, cu preturi de la <span class="green-highlight">5 RON/mp</span>.
      </p>
      <hr>

      <h2>Curatare mocheta si covoare la domiciliu</h2>
      <p>
        <strong>Mocheta reprezinta una dintre cele mai utile solutii de acoperire a pardoselilor, ce ofera confort si
          caldura
          suprafetelor</strong>. Se foloseste in spatiile publice cu trafic greu si intens, in hoteluri, birouri, sali
        de
        conferinte, dar si in multe dintre locuintele actuale. Insa pe cat este de utila, pe atat de dificila poate sa
        fie curatarea acesteia.
        Curatarea prin aspirare clasica nu poate sa elimine in totalitate praful strans in perii desi ai mochetei.
        Curatarea umeda a petelor, nu este o solutie adecvata, deoarece apa patrunsa in interiorul materialului poate sa
        dezlipeasca lipiciul. In plus, creste riscul apartitiei mucegaiului, intrucat uscarea nu este suficient de
        rapida.
        <strong> Serviciile de curatare mocheta sunt realizate de catre o echipa specializata, cu experienta si exclusiv
          cu
          echipamente de ultima generatie.</strong>

      </p>

      <app-service-section></app-service-section>
      <h3>Beneficiile curatarii profesionale ale mochetei</h3>


      <app-link-services>

        <app-links
          linkTitle="Uscarea rapida - prin tehnologia injectie-extractie nu permite solutiei active sa ramana in tesatura mochetei. Folosim solutii de curatare ce redau stralucirea tesaturii, lasand in urma o mocheta curata si un miros fresh."
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Rezultate garantate - folosim mai multe metode individuale (spalare mocheta cu spuma in mediu uscat, metoda injectie-ectractie, curatarea la suprafata cu padul textil) ."
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="Reda culorile vii - de cele mai multe ori, atunci cand vrei sa cureti mocheta singur, nu faci altceva decat sa intinzi murdaria, nu sa o elimini, fapt care duce nu doar la crearea unui spatiu neigienic, dar si la aspectul patat si lipsit de stralucire al intregului spatiu."
          linkIcon="checked"></app-links>
        <app-links
          linkTitle="O mocheta curata inseamna sanatate -distruge bacteriile, acarienii, polenul si parul de animale"
          linkIcon="checked"></app-links>
      </app-link-services>

      <p>Folosim doar produse de curatat netoxice, biodegradabile si hipoalergenice, pentru a curata perfect orice tip
        de mocheta si covor, oriunde in Bucuresti si Ilfov. Contacteaza-ne pentru a stabili data si ora.
      </p>
    </div>
  </div><!-- container /- -->
</div><!-- Service Section /- -->
<app-simple-from-contact></app-simple-from-contact>
