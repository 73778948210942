<!-- Page Banner -->
<div id="page-banner" class="page-banner">
    <img src="/assets/images/page-banner.png" alt="page-banner" />
    <!-- container -->
    <div class="page-detail">
        <div class="container">
            <h3 class="page-title">404</h3>
            <div class="page-breadcrumb pull-right">	
                <ol class="breadcrumb">
                    <li><a title="Acasa" [routerLink]="['/']">Acasa</a></li>
                    <li><a title="Servicii curatenie"[routerLink]="['/servicii-curatenie']">Servicii curatenie</a></li>
                    <li>404</li>
                </ol>
            </div>
        </div>
    </div><!-- container /- -->
</div><!-- Page Banner /- -->

<!-- Error Page Section -->
<div id="error-page-section" class="error-page-section">
    <!-- container -->
    <div class="container">
        <!-- col-md-6 -->
        <div class="col-md-6 error-content">
            <img src="/assets/images/404-1.jpg" alt="404" />
            <h3>Ups! Se pare ca am curatat prea bine.</h3>
            <p>Cauti serviciile noastre de curatenie? <a [routerLink]="['/servicii-curatenie']">Le gasesti aici</a>. 
            </p>
         
        </div><!-- col-md-6 -->
        <!-- col-md-6 -->
        <div class="col-md-6">
            <img src="/assets/images/not-found.jpg" alt="404" />
        </div><!-- col-md-6 -->
    </div><!-- container -->
</div>
<!-- Error Page Section /- -->