<!-- col-md-3 -->
<div class="col-md-3 col-sm-4 content-sidebar">
  <aside class="widget service-category-widget">
    <h3>SERVICII DE CURATENIE</h3>
    <ul>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie Birouri" [routerLink]="['/curatenie-birouri']">Curatenie
          Birouri</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie la Domiciliu"
          [routerLink]="['/curatenie-la-domiciliu']">Curatenie la Domiciliu</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie Spatii Comerciale"
          [routerLink]="['/curatenie-spatii-comerciale']">Curatenie Spatii
          Comerciale</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie Spatii Industriale"
          [routerLink]="['/curatenie-spatii-industriale']">Curatenie Spatii
          Industriale</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie dupa Constructor"
          [routerLink]="['/curatenie-dupa-constructor']">Curatenie dupa
          Constructor</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie dupa Evenimente"
          [routerLink]="['/curatenie-dupa-evenimente']">Curatenie dupa
          Evenimente</a></li>
     
    </ul>
  </aside>
  <aside class="widget service-category-widget">
    <h3>SERVICII DE CURATARE</h3>
    <ul>
      <li><i class="fa fa-long-arrow-right"></i><a title="Canapele si Fotolii" [routerLink]="['/curatare-canapele']">Canapele si
          Fotolii</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Mocheta si Covor" [routerLink]="['/curatare-mocheta-covor']">Mocheta si
          Covor</a></li>
      <li><i class="fa fa-long-arrow-right"></i><a title="Spalare Mecanizata Podele"
          [routerLink]="['/spalare-mecanizata-podele']">Spalare Mecanizata
          Podele</a></li>
          <li><i class="fa fa-long-arrow-right"></i><a title="Curatenie in Masina" [routerLink]="['/curatare-tapiterie-auto-detailing']">Curatenie in
            Masina</a></li>
    </ul>
  </aside>
  <aside class="widget contact-widget">
    <h3>Contacteaza-ne</h3>
    <ul>
      <li>
        <img src="/assets/images/icon/smartphone.svg" alt="">
        <a href="tel:+40744192411">(0744) 192 411</a>
      </li>
      <li>
        <img src="/assets/images/icon/email.svg" alt="">
        <a href="mailto:hello@curatanieperfecta.ro">hello@curatanieperfecta.ro</a>
      </li>
      <li>

        <img src="/assets/images/icon/clock.svg" alt="">
        <div class="info">
          <p>Luni - Sambata</p>
          <p>08:00 - 20:00</p>
        </div>

      </li>
    </ul>
  </aside>
  <aside class="widget prices-widget" *ngIf="router.url.includes('/curatenie-la-domiciliu')">
    <h3>Preturi curatenie la domiciliu</h3>
    <ul>
      <li>Garsoniera de la 400 RON</li>
      <li>Ap. 2 camere de la 450 RON</li>
      <li>Ap. 3 Camere de la 550 RON</li>
      <li>Ap. 4 Camere de la 650 RON</li>
      <li>Vila intre 4-6 lei/mp</li>
    </ul>
  </aside>

  <aside class="widget prices-widget" *ngIf="router.url.includes('/curatare-tapiterie-auto-detailing')">
    <h3>Preturi Curatenie in Masina</h3>
    <ul>
      <li>Autoturism de la 300 RON</li>
      <li>Cabina TIR de la 500 RON</li>
      <li>Autobuz de la 800 RON *</li>
      <li>Rulota de la 400 RON</li>
      <li>Avion de la 3000 RON *</li>
      <br><br>
      <p>* in functie de nr. de locuri</p>

    </ul>
  </aside>

  <aside class="widget prices-widget" *ngIf="router.url.includes('/curatare-canapele')">
    <h3>Preturi Spalare Canapele</h3>
    <ul>
      <li>5 Locuri 250 RON</li>
      <li>3 Locuri 150 RON</li>
      <li>2 Locuri 100 RON</li>
      <li>Fotolii 50 RON</li>
      <li>Scaune de la 20 RON</li>

    </ul>
  </aside>

</div><!-- col-md-3 /- -->
