	<!-- Industry Serve -->
	<section id="industry-serve-section" class="industry-serve-section ow-section">
        <h1> AU INCREDERE IN NOI</h1>
            <!-- container -->
            <div class="container">
          
                <!-- col-md-6 -->
                <div class="col-md-6 col-xs-12">
                    <!-- Section Header -->
            
            
            <ul>
              <li>
                <img src="/assets/images/andys-1.png" alt="">
              </li>
              <li>
                <img src="/assets/images/antena.png" alt="">
              </li>
              <li>
                <img src="/assets/images/cersanit-1.png" alt="">
              </li>
              <li>
                <img src="/assets/images/claas.jpg" alt="">
              </li>
              <li>
                <img src="/assets/images/Cora-150x128.png" alt="">
              </li>
              <li>
                <img src="/assets/images/jean-monnet-1.png" alt="">
              </li>
              <li>
                <img src="/assets/images/laplacinte-1.png" alt="">
              </li>
              <li>
                <img src="/assets/images/orange-3.png" alt="">
              </li>
              <li>
                <img src="/assets/images/penny-market-2.png" alt="">
              </li>
              <li>
                <img src="/assets/images/pro-tv-1.png" alt="">
              </li>
              <li>
                <img src="/assets/images/ramada-2-150x53.png" alt="">
              </li>
              <li>
                <img src="/assets/images/penny-market-2.png" alt="">
              </li>
              <li>
                <img src="/assets/images/sonny.png" alt="">
              </li>
              <li>
                <img src="/assets/images/stejarii-2.png" alt="">
              </li>
              <li>
                <img src="/assets/images/tiriac.jpg" alt="">
              </li>
              <li>
                <img src="/assets/images/yacht-77x150.png" alt="">
              </li>
              
            </ul>
                </div><!-- col-md-6 /- -->
                
                
                <!-- col-md-6 -->
                <div class="col-md-6 col-xs-12 testimonials">
                    <!-- Section Header -->
            
                        <!-- industry-serve -->
                        <div class="industry-serve">
                            <div id="testimonial" class="carousel slide testimonial" data-ride="carousel">
                            <!-- Indicators -->
                            <ol class="carousel-indicators">
                                <li data-target="#testimonial" data-slide-to="0" class="active"></li>
                                <li data-target="#testimonial" data-slide-to="1"></li>
                                <li data-target="#testimonial" data-slide-to="2"></li>
                            </ol>
                            
                            <div class="carousel-inner" role="listbox">
                                <div class="item active">
                                    <img src="/assets/images/testimonial/stejarii.jpg" alt="Stejarii Country Club" />
                                    <div class="carousel-caption">									
                                        <p>Echipa de la CURATENIE PERFECTA au dat dovada de profesionalism, promtitudine si o capacitate marede adaptare.</p>
                                        <h3>- CRISTINA ENACHE <span>Stejarii Country Club</span></h3>
                                    </div>
                                </div>
                                
                                <div class="item">
                                    <img src="/assets/images/testimonial/class.jpg" alt="CLASS Regional Center" />
                                    <div class="carousel-caption">									
                                        <p> De-a lungul colaborarii, care dureaza deja de cativa ani, au dovedit o calitate deosebita a serviciilor prestate, eficienta si profesionalism.</p>
                                        <h3><span>CLASS Regional Center</span></h3>
                                    </div>
                                </div>
                                
                                <div class="item">
                                    <img src="/assets/images/testimonial/sony.jpg" alt="Service Delivery Manager SONY" />
                                    <div class="carousel-caption">
                                        <p>Ne exprimam aprecierea fata de echipa de curatenie de la Curtatenie Perfecta. Lucram impreuna inca din 2009, iar pana in prezent avem o colaborare foarte buna.</p>
                                        <h3>- CRISTINA COSTESCU <span>Service Delivery Manager SONY</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div><!-- industry-serve -->
                </div><!-- col-md-6 /- -->
            </div><!-- container /- -->
        </section><!-- Industry Serve /- -->