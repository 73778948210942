<section>
  <div class="container">
      <p>Preturile afisate sunt orientative, acestea variaza in functie de complexitatea si suprafata spatiului ce trebuie curatat, de gradul de murdarie si de cerintele suplimentare. Aceste criterii pot fi stabilite dupa o vizionare a spatiului de catre unul din agentii nostri de curatenie.
    </p>
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <h4>Curatenie acasa</h4>
        <ul class="constructor-services">
          <li>Garsoniera de la 400 RON</li>
          <li>Ap. 2 camere de la 450 RON</li>
          <li>Ap. 3 Camere de la 550 RON</li>
          <li>Ap. 4 Camere de la 650 RON</li>
          <li>Vila intre 4-6 lei/mp</li>
        </ul>
      </div>
      <div class="col-md-4 col-xs-12">
        <h4>Curatenie masini</h4>
        <ul class="constructor-services">
          <li>Autoturism de la 300 RON</li>
          <li>Cabina TIR de la 500 RON</li>
          <li>Autobuz de la 800 RON *</li>
          <li>Rulota de la 400 RON</li>
          <li>Avion de la 3000 RON *</li>
          <li>* in functie de nr. de locuri</li>
        </ul>
      </div>
      <div class="col-md-4 col-xs-12">
        <h4>Curatare canapele/ fotolii</h4>
        <ul class="constructor-services">
          <li>5 Locuri 250 RON</li>
          <li>3 Locuri 150 RON</li>
          <li>2 Locuri 100 RON</li>
          <li>Fotolii 50 RON</li>
          <li>Scaune de la 20 RON</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-xs-12">
        <h4>Curatenie serviciu</h4>
        <ul class="constructor-services">
          <li>De la 5 RON / mp</li>
        </ul>
      </div>
      <div class="col-md-4 col-xs-12">
        <h4>Curatare Geamuri</h4>
        <ul class="constructor-services">
          <li>De la 4 RON /mp</li>
        </ul>
      </div>
      <div class="col-md-4 col-xs-12">
        <h4>Curatenie Ecologica</h4>
        <ul class="constructor-services">
          <li>Tarifele sunt negociabile.</li>
        </ul>
      </div>
     
    </div>
   

    <div class="row">
       
        <div class="col-md-4 col-xs-12">
          <h4>Curatenie pardoseala</h4>
          <ul class="constructor-services">
  
            <li>Curatenie pardoseala</li>
            <li>Mocheta de la 5 RON / mp*</li>
            <li>Pardoseala dura de la 7 RON /mp*</li>
            <li>* in functie de gradul de murdarie.</li>
          </ul>
        </div>
        <div class="col-md-4 col-xs-12">
          <h4>Curatenie dupa constructor</h4>
          <ul class="constructor-services">
            <li>Intre 8 RON si 15 RON / mp*</li>
            <li>* in functie de gradul de murdarie.</li>
          </ul>
        </div>
      </div>



  </div>
</section>
