<section class="utils">
    <div class="container">
        <accordion [options]="options" [menus]="menus"></accordion>
        <div class="questions">
            <p><strong>MAI AI INTREBARI?</strong></p>
            <p>
                Te rugam sa ne suni folosind numarul de telefon <a href="tel:+40744192411">(0744) 192 411</a> sau sa ne trimiti un email la adresa <a href="mailto:hello@curatenieperfecta.ro">hello@curatenieperfecta.ro</a>
            </p>
        </div>
       
    </div>
   
</section>

