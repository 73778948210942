<section>
  <div class="container">
    <div id="contents">
      <style type="text/css">
        ul.lst-kix_717vgwbof9vh-1 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-0 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-5 {
          list-style-type: none
        }

        .lst-kix_717vgwbof9vh-0>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_717vgwbof9vh-4 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-3 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-2 {
          list-style-type: none
        }

        .lst-kix_717vgwbof9vh-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_717vgwbof9vh-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_717vgwbof9vh-2>li:before {
          content: "\0025a0  "
        }

        .lst-kix_717vgwbof9vh-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_8f36keht5cu9-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_717vgwbof9vh-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_8f36keht5cu9-8>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_8f36keht5cu9-8 {
          list-style-type: none
        }

        .lst-kix_8f36keht5cu9-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_8f36keht5cu9-5>li:before {
          content: "\0025a0  "
        }

        .lst-kix_8f36keht5cu9-3>li:before {
          content: "\0025cf  "
        }

        .lst-kix_8f36keht5cu9-4>li:before {
          content: "\0025cb  "
        }

        .lst-kix_8f36keht5cu9-1>li:before {
          content: "\0025cb  "
        }

        .lst-kix_717vgwbof9vh-6>li:before {
          content: "\0025cf  "
        }

        .lst-kix_717vgwbof9vh-7>li:before {
          content: "\0025cb  "
        }

        .lst-kix_717vgwbof9vh-8>li:before {
          content: "\0025a0  "
        }

        .lst-kix_8f36keht5cu9-2>li:before {
          content: "\0025a0  "
        }

        ul.lst-kix_8f36keht5cu9-3 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-2 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-8 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-1 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-7 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-0 {
          list-style-type: none
        }

        ul.lst-kix_717vgwbof9vh-6 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-7 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-6 {
          list-style-type: none
        }

        .lst-kix_8f36keht5cu9-0>li:before {
          content: "\0025cf  "
        }

        ul.lst-kix_8f36keht5cu9-5 {
          list-style-type: none
        }

        ul.lst-kix_8f36keht5cu9-4 {
          list-style-type: none
        }

        ol {
          margin: 0;
          padding: 0
        }

        table td,
        table th {
          padding: 0
        }

        .c0 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c11 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .c4 {
          color: #000000;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Arial";
          font-style: normal
        }

        .c10 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c2 {
          padding-top: 0pt;
          padding-bottom: 10pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: justify
        }

        .c7 {
          padding-top: 0pt;
          padding-bottom: 10pt;
          line-height: 1.15;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .c3 {
          text-decoration-skip-ink: none;
          -webkit-text-decoration-skip: none;
          color: #1155cc;
          text-decoration: underline
        }

        .c6 {
          background-color: #ffffff;
          max-width: 451.4pt;
          padding: 72pt 72pt 72pt 72pt
        }

        .c9 {
          color: inherit;
          text-decoration: inherit
        }

        .c8 {
          margin-left: 36pt;
          padding-left: 0pt
        }

        .c12 {
          padding: 0;
          margin: 0
        }

        .c1 {
          font-weight: 700
        }

        .c5 {
          height: 11pt
        }

        .title {
          padding-top: 0pt;
          color: #000000;
          font-size: 26pt;
          padding-bottom: 3pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        .subtitle {
          padding-top: 0pt;
          color: #666666;
          font-size: 15pt;
          padding-bottom: 16pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        li {
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        p {
          margin: 0;
          color: #000000;
          font-size: 11pt;
          font-family: "Arial"
        }

        h1 {
          padding-top: 20pt;
          color: #000000;
          font-size: 20pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h2 {
          padding-top: 18pt;
          color: #000000;
          font-size: 16pt;
          padding-bottom: 6pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h3 {
          padding-top: 16pt;
          color: #434343;
          font-size: 14pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h4 {
          padding-top: 14pt;
          color: #666666;
          font-size: 12pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h5 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }

        h6 {
          padding-top: 12pt;
          color: #666666;
          font-size: 11pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1.15;
          page-break-after: avoid;
          font-style: italic;
          orphans: 2;
          widows: 2;
          text-align: left
        }

      </style>
      <p class="c7"><span class="c0">TERMENI SI CONDITII </span></p>
      <p class="c7"><span class="c0">Termenii şi condiţiile de utilizare a paginii de internet disponibilă la adresa
          www.curatenieperfecta.ro (denumit în continuare "Site")</span></p>
      <p class="c7"><span class="c0">1. PRIN UTILIZAREA ACESTUI WEBSITE, SUNTEȚI DE ACORD CU ACESTE CONDIȚII DE
          UTILIZARE</span></p>
      <p class="c2"><span class="c4 c1">Acest website reprezintă proprietatea CLEANSPOT TEAM SRL, denumit in continuare
          “Furnizor”</span></p>
      <p class="c2"><span class="c0">Accesul și utilizarea acestui website sunt supuse următoarilor termeni și condiții
          de utilizare și tuturor legilor și regulamentelor aplicabile. </span></p>
      <p class="c2"><span>Accesând și utilizând website-ul, acceptați, fără limitări sau calificări, aceste condiții și
          luați la cunoștință ca orice alte acorduri între dvs. și </span><span class="c1">CLEANSPOT TEAM
          SRL</span><span class="c0">&nbsp;sunt înlocuite prin prevederile prezentelor termene și condiții. </span></p>
      <p class="c2"><span class="c0">Dacă nu sunteți de acord sau nu acceptați, fără limitări sau calificări, Condițiile
          de Utilizare ale acestui Site, vă rugăm să nu folosiți acest site.</span></p>
      <p class="c2"><span class="c0">2. PROPRIETATEA CONȚINUTULUI </span></p>
      <p class="c2"><span>Website-ul și tot ceea ce cuprinde acesta, incluzând fără limitare toate textele și imaginile
          (“conținut”) sunt în proprietatea și sub dreptul de autor (copyright) al </span><span class="c1">CLEANSPOT
          TEAM SRL</span><span>, cu sediul in Str. Liviu Rebreanu, Nr.7, Bl.51, Sc.C, Et.7, Ap.118, Sector 3 Bucuresti,
          înregistrată la Registrul Comerțului sub J40/13127/2016 | având cod fiscal RO36603630, cu toate drepturile
          rezervate cu excepția cazului în care nu este altfel specificat. Orice conținut care constă într-o marcă,
          logo, sau marca de serviciu reprezintă marcă înregistrată și neînregistrată a </span><span
          class="c1">Furnizorului</span><span class="c0">. Este strict interzisă utilizarea oricărui conținut, cu
          excepția celor prevăzute în condițiile de utilizare, fără permisiunea în scris a proprietarului
          “conținutului”. </span></p>
      <p class="c2"><span>De asemenea, vă informăm că </span><span class="c1">Furnizorul</span><span
          class="c0">&nbsp;își va asigura și impune în mod hotărât recunoașterea drepturilor de proprietate intelectuală
          în conformitate cu legile în vigoare, ajungând dacă este cazul la acționarea celor vinovați de încălcarea
          dreptului de proprietate intelectuală în judecată prin instanțele penale.</span></p>
      <p class="c2"><span class="c0">3. UTILIZAREA SITE-ULUI </span></p>
      <p class="c2"><span class="c1">Furnizorul</span><span class="c0">&nbsp;acordă permisiunea de a utiliza website-ul
          în următoarele condiții: </span></p>
      <ul class="c12 lst-kix_717vgwbof9vh-0 start">
        <li class="c2 c8"><span class="c0">Puteți descărca conținutul, însă doar pentru folosul personal și ne-comercial
            și cu condiția de a vă conforma cu informațiile referitoare la dreptul de autor (copyright) și alte drepturi
            de proprietate intelectuală cuprinse în conținut; </span></li>
        <li class="c2 c8"><span>Nu aveți permisiunea să modificați, să copiați (cu excepția celor mai sus menționate),
            să transmiteți, să expuneți, să refolosiți, să reproduceți, să publicați, să autorizați, să acordați o
            licență de folosire, să creați lucrări derivate din, sau să transferați, să vindeți sau să folosiți
            conținutul în alt mod, fără acordul în scris al </span><span class="c1">Furnizorului</span><span
            class="c0">; </span></li>
        <li class="c2 c8"><span>Este interzis să folosiți website-ul pentru a afișa sau transmite orice fel de material
            ce are caracter amenințător, fals, înșelător, abuziv, de hărțuire, licențios, calomniator, vulgar, obscen,
            scandalos, instigator, pornografic sau profanator, sau orice alt fel de material care poate constitui sau
            încuraja un comportament ce va putea da naștere unei infracțiuni, sau ar putea conduce la răspundere civila,
            sau ar încălca în alt mod legea. </span><span class="c1">Furnizorul</span><span>&nbsp;va coopera cu oricare
            dintre autoritățile desemnate să aplice legea și se va conforma cu orice sentință judecătorească prin care
            se cere sau se ordonă societății </span><span class="c1">Furnizorului</span><span class="c0">&nbsp;să
            dezvăluie identitatea oricărei persoane care ar afișa sau transmite orice fel de informație sau material de
            acest fel pe sau prin intermediul website-ului. </span></li>
      </ul>
      <p class="c2"><span>Sunteți de acord ca </span><span class="c1">Furnizorul</span><span class="c0">, la alegerea
          sa, să înceteze sau să întrerupă utilizarea de către dvs. a site-ului la nerespectarea Termenilor de
          Utilizare. În momentul suspendării sau încetării respective, dumneavoastră trebuie: </span></p>
      <p class="c2"><span class="c0">(a) să încetați utilizarea site-ului și </span></p>
      <p class="c2"><span class="c0">(b) să distrugeți orice copii făcute după orice parte a conținutului său. </span>
      </p>
      <p class="c2"><span class="c0">Nu veți considera Compania răspunzătoare pentru daunele, costurile, răspunderile,
          cheltuielile (inclusiv costurile avocaților) și sumele pentru înțelegerile legate de orice proces, pretenție
          sau acțiune depusă de către un terț împotriva Companiei drept rezultat al </span></p>
      <p class="c2"><span class="c0">(a) neglijentei, proastei reprezentări, erori sau omitere din partea dvs. sau
        </span></p>
      <p class="c2"><span class="c0">(b) încălcării din partea dvs. a Termenilor de Utilizare și a legilor sau
          regulamentelor de aplicare. </span></p>
      <p class="c2"><span class="c0">4. CONFIDENȚIALITATE </span></p>
      <p class="c2"><span class="c0">Orice date cu caracter personal precum: numele, adresa, numărul de telefon sau
          adresa de e-mail a dumneavoastră pe care o transmiteți pe website, prin poșta electronică sau prin altă
          modalitate vor fi folosite în conformitate cu Politica de Confidențialitate. </span></p>
      <p class="c2"><span class="c0">5. LIPSA GARANȚIILOR </span></p>
      <p class="c2"><span class="c0">Întreg conținutul acestui website poate fi modificat și vă este oferit ”ca atare”
          fără a se oferi nici o garanție de nici un fel, fie aceasta expresă sau implicită. </span></p>
      <p class="c2"><span class="c0">6. EXONERAREA DE RĂSPUNDERE </span></p>
      <p class="c2"><span>Utilizarea acestui website este în totalitate pe răspunderea dumneavoastră. </span><span
          class="c1">Furnizorul</span><span>, funcționarii, directorii, agenții sau orice altă parte implicată în
          conceperea, producerea sau oferirea website-ului nu sunt răspunzătoare pentru daune directe sau indirecte, de
          orice natură ce ar rezulta din sau în legătură cu utilizarea acestui website sau a conținutului său.
        </span><span class="c1">Furnizorul</span><span class="c0">&nbsp;nu își asumă nici o responsabilitate, și nu va
          fi răspunzătoare pentru nici o daună sau viruși care ar putea să vă infecteze computerul sau alte bunuri în
          urma accesării sau utilizării acestui website, sau descărcării oricărui material, informații, text, imagini
          video sau audio de pe acest website. </span></p>
      <p class="c2"><span class="c0">7. LINK-URI PE SITE-URILE UNEI TERȚE PĂRȚI </span></p>
      <p class="c2"><span>Site-ul poate conține link-uri către alte site-uri aflate în proprietatea sau operate de alte
          părți decât </span><span class="c1">Furnizorul</span><span>. Astfel de link-uri vă sunt furnizate pentru a le
          folosi numai dacă veți dori aceasta.</span><span class="c1">Furnizorul</span><span class="c0">&nbsp;nu
          controlează și nu este răspunzătoare pentru conținutul și condițiile de confidențialitate sau securitate și de
          funcționalitatea acestor site-uri. </span></p>
      <p class="c2"><span>Fără a se limita la cele mai sus menționate, </span><span class="c1">Furnizorul</span><span
          class="c0">&nbsp;își declină în mod special orice răspundere dacă aceste site-uri: </span></p>
      <ul class="c12 lst-kix_8f36keht5cu9-0 start">
        <li class="c10 c8"><span class="c0">Încalcă drepturile de proprietate intelectuală ale unei terțe părți; </span>
        </li>
        <li class="c10 c8"><span class="c0">Sunt inexacte, incomplete sau conțin informații înșelătoare; </span></li>
        <li class="c8 c10"><span class="c0">Nu au caracter comercial sau nu răspund îndeplinirii unui anumit scop;
          </span></li>
        <li class="c10 c8"><span class="c0">Nu oferă o securitate adecvată; </span></li>
        <li class="c10 c8"><span class="c0">Conțin viruși sau alte elemente cu caracter distructiv; </span></li>
        <li class="c2 c8"><span class="c0">Sunt licențioase sau calomnioase. </span></li>
      </ul>
      <p class="c2"><span class="c0">8. POLITICA FIȘIERELOR COOKIE </span></p>
      <p class="c2"><span class="c0">Un cookie este un fișier text care conține bucăți de date, colectate atunci când
          vizitați un website. Este proiectat pentru a ajuta website-urile să identifice activitatea trecută a
          utilizatorilor. Acest lucru poate include acțiunea de a urmări dacă ați făcut click pe link-uri speciale sau
          pagini, dacă v-ați conectat la profilul dumneavoastră, sau dacă ați citit anumite pagini într-o perioadă de
          mai multe luni. </span></p>
      <p class="c2"><span class="c0">Există diferite tipuri de cookie-uri și fără ele website-urile nu ar putea
          funcționa în modul în care v-ați obișnuit să le utilizați. </span></p>
      <p class="c2"><span class="c0">Website-ul www.curatenieperfecta.ro utilizează cookie-uri speciale pentru a va
          oferi cea mai bună experiență posibilă și sunt utilizate doar acele cookie-uri pe care le considerăm necesare
          și utile. </span></p>
      <p class="c2"><span>Va rugam sa consultati pagina dedicata </span><span>Politicii de Cookie</span><span
          class="c0">. Vă explicăm ce tipuri de cookie-uri sunt folosite pe acest website, astfel încât să puteți decide
          dacă sunteți mulțumit, dacă doriți să ștergeți cookie-urile existente sau chiar dezactiva folosirea
          cookie-urilor atunci când vizualizați website-ul nostru. </span></p>
      <p class="c2"><span class="c0">9. REVIZUIRI ALE ACESTOR TERMENE ȘI CONDIȚII </span></p>
      <p class="c2"><span class="c1">Furnizorul</span><span class="c0">&nbsp;poate, în orice moment și fără notificare
          prealabilă, să revizuiască aceste termene și condiții prin actualizarea acestora. Sunteți obligat să
          respectați oricare și toate astfel de revizuiri și de aceea va trebui să vizitați această pagină a site-ului
          nostru în mod periodic pentru a lua la cunoștință condițiile de utilizare actualizate. </span></p>
      <p class="c2"><span class="c1 c4">Legislație aplicabilă și Jurisdicție </span></p>
      <p class="c2"><span class="c0">Aceste termene și condiții și utilizarea acestui website sunt guvernate de legile
          din România. Instanțele competente din România vor avea jurisdicție exclusivă asupra oricăror și tuturor
          disputelor ce vor apărea din sau se vor referi la sau vor fi în legătură cu prevederile condițiilor de
          utilizare și/sau conținutul site-ului sau în cazul disputelor în care aceste termene și condiții și/sau acest
          website vor fi considerate fapte relevante pentru astfel de dispute.</span></p>
      <hr>
      <p class="c2 c5"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Pentru orice tip de întrebare sau nelămurire, vă rugăm să ne contactați folosind
          următoarele date:</span></p>
      <p class="c2"><span class="c1">Email:</span><span>&nbsp;</span><span class="c3"><a class="c9"
            href="mailto:contact@restaurant-planb.ro">contact@curatenieperfecta.ro</a></span><span
          class="c0">&nbsp;</span></p>
      <p class="c2"><span class="c1">Telefon</span><span class="c0">: 0747.171.409</span></p>
      <p class="c2"><span class="c1">Adresa:</span><span class="c0">&nbsp;Str. Liviu Rebreanu, Nr.7, Bl.51, Sc.C, Et.7,
          Ap.118, Sector 3 Bucuresti</span></p>
      <p class="c2 c5"><span class="c0"></span></p>
      <p class="c11 c5"><span class="c0"></span></p>
      <p class="c5 c11"><span class="c0"></span></p>
    </div>
  </div>
</section>
