import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-floor-cleaning',
  templateUrl: './floor-cleaning.component.html',
  styleUrls: ['./floor-cleaning.component.scss']
})
export class FloorCleaningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
