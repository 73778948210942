<!-- Slider Section -->
<div id="slider-section" class="slider-section">
  <div id="make-clean-slider" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#make-clean-slider" data-slide-to="0" class="active"></li>
      <li data-target="#make-clean-slider" data-slide-to="1"></li>
      <li data-target="#make-clean-slider" data-slide-to="2"></li>
    </ol>

    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <img src="/assets/images/slider/curatenie-perfecta-1.jpg" alt="slide">
        <div class="carousel-caption">
          <div class="container">
            <div class="col-md-12">
              <h3>CURATENIE PERFECTA PENTRU <strong>AFACEREA TA</strong></h3>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <img src="/assets/images/slider/curatenie-perfecta2.jpg" alt="slide">
        <div class="carousel-caption">
          <div class="container">
            <div class="col-md-12">
              <h3>CURATENIE PERFECTA PENTRU <strong>Locuinta TA</strong></h3>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- Controls -->
    <a title="Slider Control" class="left carousel-control" href="#make-clean-slider" role="button" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a title="Slider Control" class="right carousel-control" href="#make-clean-slider" role="button" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div class="container">
    <app-quick-form-contact></app-quick-form-contact>
  </div>
</div><!-- Slider Section /- -->
<section class="subslider-section">
  <div class="container">
    <h3>
      FIRMA DE CURATENIE PROFESIONALA IN BUCURESTI SI ILFOV
    </h3>
    <a [routerLink]="['/servicii-curatenie']">Servicii</a>

  </div>
</section>


<app-service-section></app-service-section>
<!-- Service Section -->
<section id="serviceSection" class="service-section">
  <!-- container -->
  <div class="container">
    <div class="col-md-6 col-sm-6 img">
      <img src="/assets/images/curatenie pentru afaceri.jpg" alt="curatenie pentru afaceri" />
    </div>
    <div class="col-md-6 col-sm-6 service-content pl-40">
      <!-- Section Header -->
      <div class="section-header">
        <h3>CURATENIE PENTRU ZONA BUSINESS</h3>
      </div><!-- Section Header /- -->
      <p>Suntem dedicati 100% in oferirea de solutii potrivite tie si afacerii tale, pentru a asigura un mediu curat si
        sanatos de lucru!</p>

      <div class="service-content-box">
        <ul>


          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Birouri </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Spatii Comerciale </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Hoteluri </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Restaurante </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Hale & Depozite </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Cabinete Medicale </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Aeroporturi </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Scoli & Gradinite </li>

        </ul>
      </div>
    </div>
  </div><!-- container /- -->
</section><!-- Service Section /- -->

<!-- Service Section -->
<section id="serviceSection" class="service-section">
  <!-- container -->
  <div class="container">
    <div class="col-md-6 col-sm-6 service-content pr-40">
      <!-- Section Header -->
      <div class="section-header">
        <h3>CURATENIE PENTRU CASA TA</h3>
      </div><!-- Section Header /- -->
      <p>Relaxeaza-te, lasa treaba in grija noastra! Pentru tine inseamna mai mult timp liber de care sa profiti, iar
        noua chiar ne place ceea ce facem.</p>

      <div class="service-content-box">
        <ul>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Vile & Case </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Apartamente </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Garsoniere </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Curatenie Generala </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Curatenie de Intretinere </li>
          <li>
            <img src="/assets/images/icon/checked.svg" alt="">
            Abonamente </li>
          

        </ul>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 img">
      <img src="/assets/images/curatenie rezidentiala.jpg" alt="curatenie rezidentiala" />
    </div>

  </div><!-- container /- -->
</section><!-- Service Section /- -->



<app-service-biocid></app-service-biocid>

<!-- Service Section -->
<section id="service-section" class="service-section service-carousel ow-section">
  
  <!-- container -->
  <div class="container">

    <!-- Section Header -->
    <div class="section-header">
      <h1>CURATENIE PERFECTA</h1>
      <h2>Un MINDSET al echipei noastre, nu doar o promisiune</h2>
      <h3><img src="/assets/images/icon/sep-icon.png" alt="sep-icon" /> Ce servicii ce curatenie iti oferim?
      </h3>
    </div><!-- Section Header /- -->

    <div id="make-clean-service" class="owl-carousel owl-theme services-style1">
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-birouri.jpg" alt="curatenie birouri" />
          <div class="service-box-inner">
            <h4> Curatenie Birouri </h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-birouri']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-la-domiciliu.jpg" alt="curatenie la domiciliu" />
          <div class="service-box-inner">
            <h4> Curatenie la Domiciliu</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-la-domiciliu']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-spatii-comerciale.jpg" alt="curatenie spatii comerciale" />
          <div class="service-box-inner">
            <h4>Curatenie Spatii Comerciale</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-spatii-comerciale']">vezi detalii</a>
          </div>
        </div>
      </div>
    
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-industriala.jpg" alt="curatenie spatii industriale" />
          <div class="service-box-inner">
            <h4> Curatenie Spatii Industriale</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-industriala']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-dupa-constructor.jpg" alt="curatenie dupa constructor" />
          <div class="service-box-inner">
            <h4>Curatenie dupa Constructor</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-dupa-constructor']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatenie-dupa-evenimente.jpg" alt="curatenie dupa evenimente" />
          <div class="service-box-inner">
            <h4>Curatenie dupa Evenimente</h4>
            <a title="vezi detalii" [routerLink]="['/curatenie-dupa-evenimente']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatare-tapiterie-auto-detailing-auto.jpg" alt="curatenie in masina" />
          <div class="service-box-inner">
            <h4>Curatenie in Masina</h4>
            <a title="vezi detalii" [routerLink]="['curatare-tapiterie-auto-detailing']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatare-canapele.jpg" alt="curatare canapele" />
          <div class="service-box-inner">
            <h4>Curatare Canapele</h4>
            <a title="vezi detalii" [routerLink]="['/curatare-canapele']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/curatare-mocheta.jpg" alt="curatare mocheta" />
          <div class="service-box-inner">
            <h4>Curatare Mocheta</h4>
            <a title="vezi detalii" [routerLink]="['/curatare-mocheta-covor']">vezi detalii</a>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="service-box">
          <img src="/assets/images/services/spalare-mecanizata-podele.jpg" alt="spalare mecanizata podele" />
          <div class="service-box-inner">
            <h4>Spalare Mecanizata Podele</h4>
            <a title="vezi detalii" [routerLink]="['/spalare-mecanizata-podele']">vezi detalii</a>
          </div>
        </div>
      </div>
    </div>
  </div><!-- container /- -->
</section><!-- Service Section /- -->

<app-service-perfect-clean></app-service-perfect-clean>

<app-service-testimonials></app-service-testimonials>
