<section class="services-section">
    <div class="container">
      <ul>
        <li>
          <img src="/assets/images/icon/icon1.jpg" alt="">
          <h4>ECHIPA DE INCREDERE</h4>
          <p>Ne asiguram ca angajatii nostri sunt motivati sa
            lucreze la cele mai inalte standarde ale calitatii
          </p>
        </li>
        <li>
          <img src="/assets/images/icon/icon2.jpg" alt="">
          <h4>AVEM EXPERIENTA</h4>
          <p>Cei peste 10 ani de activitate in domeniul curateniei
            si a igienizarii isi spun cuvantul de fiecare data
          </p>
        </li>
        <li>
          <img src="/assets/images/icon/icon3.jpg" alt="">
          <h4>SUNTEM FLEXIBILI</h4>
          <p>Ne pliem pe necesitatile tale, astfel incat, impreuna
            sa obtinem mereu cele mai bune rezultate
          </p>
        </li>
      </ul>
    </div>
  </section>
  