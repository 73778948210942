import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comercial-cleaning',
  templateUrl: './comercial-cleaning.component.html',
  styleUrls: ['./comercial-cleaning.component.scss']
})
export class ComercialCleaningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
