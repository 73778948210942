<section class="section-dezinfectare">
    <div class="container">
     
      <!-- <h4 *ngIf="router.url == '/'">Nu doar experienta ne defineste calitatea serviciilor de curatenie pe care le oferim, ci mai ales viziunea fata 
        de activitatea pe care o desfasuram. 
        <span>Curatam in profunzime</span> orice tip de suprafata, lasand pentru final cel mai important aspect <span>dezinfectarea</span>
      </h4> -->
      <h1>Folosim <span>dezinfectant BIOCID</span> avizat de Ministerul Sanatatii
        pe toate suprafetele pe care le curatam
      </h1>
    
      <h4 *ngIf="router.url.includes('/servicii-curatenie')">
        Ne deplasam pentru servicii profesionale de curatenie oriunde in <span>Bucuresti si Ilfov</span>

      </h4>
      
    </div>
  
  </section>