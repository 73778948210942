	<!-- Footer Section -->
	<footer id="footer-section" class="footer-section ow-background">
	  <!-- container -->
	  <div class="container">
	    <div class="footer-heading">
	      <h5>Ai intrebari?</h5>
	      <h3>Te rugam sa ne suni folosind numarul de telefon <a href="tel:+40744192411">(0744) 192 411</a></h3>
	    </div>
	    <aside class="col-md-3 col-sm-3 widget widget-link">
	      <h3>Servicii curatenie</h3>
	      <ul>
	        <li><a title="Curatenie Birouri" [routerLink]="['/curatenie-birouri']"><i class="fa fa-tint"></i>Curatenie Birouri</a></li>
	        <li><a title="Curatenie la Domiciliu"[routerLink]="['curatenie-la-domiciliu']"><i class="fa fa-tint"></i>Curatenie la
	            Domiciliu</a></li>
	        <li><a title="Curatenie Spatii Comerciale"[routerLink]="['curatenie-spatii-comerciale']"><i
	              class="fa fa-tint"></i>Curatenie Spatii
	            Comerciale</a></li>
	        <li><a title="Curatenie Spatii Industriale"[routerLink]="['curatenie-spatii-industriale']"><i
	              class="fa fa-tint"></i>Curatenie Spatii
	            Industriale</a></li>
	        <li><a title="Curatenie dupa Constructor"[routerLink]="['curatenie-dupa-constructor']"><i
	              class="fa fa-tint"></i>Curatenie dupa
	            Constructor</a></li>
	        <li><a title="Curatenie dupa Evenimente"[routerLink]="['curatenie-dupa-evenimente']"><i class="fa fa-tint"></i>Curatenie
	            dupa
	            Evenimente</a></li>


	      </ul>
	    </aside>
	    <aside class="col-md-3 col-sm-3 widget widget-link">
	      <h3>Servicii curatare</h3>
	      <ul>
	        <li><a title="Canapele si Fotolii" [routerLink]="['curatare-canapele']"><i class="fa fa-tint"></i>Canapele si Fotolii</a>
	        </li>
	        <li><a title="Mocheta si Covor" [routerLink]="['curatare-mocheta-covor']"><i class="fa fa-tint"></i>Mocheta si Covor</a>
	        </li>
	        <li><a title="Spalare Mecanizata Podele" [routerLink]="['spalare-mecanizata-podele']"><i class="fa fa-tint"></i>Spalare
	            Mecanizata
	            Podele</a></li>
	        <li><a title="Curatenie in Masina" [routerLink]="['curatare-tapiterie-auto-detailing']"><i
	              class="fa fa-tint"></i>Curatenie in Masina</a></li>
	      </ul>
	    </aside>
	    <aside class="col-md-3 col-sm-3 widget widget-link">
	      <h3>Utile</h3>
	      <ul>
	        <li><a title="Setari GDPR" [routerLink]="['/setari-gdpr']"><i class="fa fa-tint"></i> Setari GDPR</a></li>
	        <li><a title="Confidentialitate" [routerLink]="['/confidentialitate']"><i class="fa fa-tint"></i> Confidentialitate</a>
	        </li>
	        <li><a title="Politica Cookies" [routerLink]="['/setari-cookies']"><i class="fa fa-tint"></i> Politica Cookies</a></li>
	        <li><a title="Termeni si Conditii" [routerLink]="['/termeni-si-conditii']"><i class="fa fa-tint"></i> Termeni si
	            Conditii</a></li>
	      </ul>
	    </aside>

	    <aside class="col-md-2 col-sm-2 widget widget-link">
	      <h3>linkuri</h3>
	      <ul>
	        <li> <a title="Acasa" [routerLink]="['/']"><i class="fa fa-tint"></i>Acasa</a></li>
	        <li><a title="Servicii curatenie" [routerLink]="['/servicii-curatenie']"><i class="fa fa-tint"></i>Servicii Curatenie</a>
	        </li>
	        <li><a title="Preturi" [routerLink]="['/preturi']"><i class="fa fa-tint"></i>Preturi</a></li>
	        <li><a title="Utile" [routerLink]="['/utile']"><i class="fa fa-tint"></i>Utile</a></li>
	        <li><a title="Despre noi" [routerLink]="['/despre-noi']"><i class="fa fa-tint"></i>Despre noi</a></li>
	        <li><a title="Contact" [routerLink]="['/contact']"><i class="fa fa-tint"></i>Contact</a></li>
	      </ul>
	    </aside>

	    <!-- <aside class="col-md-4 col-sm-4 widget widget-calculator">
	      <h3>Price Calculator</h3>
	      <form>
	        <input type="text" name="square-meter" placeholder="SIZE OF AREA  (sQUARE METERS)" required />
	        <select>
	          <option>choose the type of service</option>
	          <option>choose the type of service</option>
	          <option>choose the type of service</option>
	          <option>choose the type of service</option>
	        </select>
	        <input type="submit" value="submit query" />
	      </form>
	    </aside> -->

	    <!-- Footer Bottom -->
	    <div class="footer-bottom">
	      <p>Copyright © {{getYear}} Curatenie Perfecta.</p>
	      <div class="social">
	        <a href="https://www.facebook.com/CurateniePerfectaBucuresti/">
	          <img src="/assets/images/icon/facebook.svg" alt="">
	        </a>
	      </div>
	    </div>
	    <!-- Footer Bottom /- -->

	  </div>
	  <!-- container /- -->
	</footer><!-- Footer Section /- -->
	<div id="openModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="openModalLabel"
	  style="display: none;" aria-hidden="true">
	  <div class="modal-dialog modal-dialog-centered" role="document">

	    <div class="modal-content">
	      <div class="modal-header">
	        <h3 class="heading-3">CERE O ESTIMARE GRATUITA</h3>
	        <a class="close" id="closeModal" #closeModal data-dismiss="modal"><img src="/assets/images/icon/close.svg"
	            alt=""></a>
	      </div>
	      <div class="popup">

	        <div class="content">
	          <app-form-contact-categories></app-form-contact-categories>

	        </div>

	      </div>
	    </div>
	  </div>
	</div>
