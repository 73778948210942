<form class="form-wrapper" [formGroup]="form" (ngSubmit)="submit()">
  <div class="main-contact-form" method="post">
    <div class="col-md-6 col-sm-6">
      <label>Nume <span>*</span></label>
      <input formControlName="name" id="name" type="text" class="form-control">

      <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
        <div *ngIf="f.name.errors.required">Introduceti numele</div>
        <div *ngIf="f.name.errors.minlength">Numele trebuie sa contina cel putin 3 litere</div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6">
      <label>Email <span>*</span></label>
      <input formControlName="email" id="email" type="text" class="form-control " placeholder="Email">
      <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
        <div *ngIf="f.email.errors.required">Introduceti emailul</div>
        <div *ngIf="f.email.errors.minlength">Formatul nu este corect</div>
        <div *ngIf="f.email.errors.required">Formatul nu este corect</div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6">
      <label>Telefon <span>*</span></label>
      <input formControlName="phone" id="phone" type="text" class="form-control" placeholder="Telefon">
      <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
        <div *ngIf="f.phone.errors.required">Introduceti numarul</div>
        <div *ngIf="f.phone.errors.pattern">Telefonul nu are formatul corect</div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6">
      <label>Sunt interesat de:</label>
      <select name="categories" id="categories" formControlName="categories" [(ngModel)]="categories">
        <option value="Curatenie Birouri">Curatenie Birouri</option>
        <option value="Curatenie la Domiciliu">Curatenie la Domiciliu</option>
        <option value="Curatenie Spatii Comerciale">Curatenie Spatii Comerciale</option>
        <option value="Curatenie Spatii Industriale">Curatenie Spatii Industriale</option>
        <option value="Curatenie dupa Constructor">Curatenie dupa Constructor</option>
        <option value="Curatenie dupa Evenimente">Curatenie dupa Evenimente</option>
        <option value="Curatare Canapele/Fotolii">Curatare Canapele/Fotolii</option>
        <option value="Curatere Mocheta/Covor">Curatere Mocheta/Covor</option>
        <option value="Spalare Mecanizata">Spalare Mecanizata Podele</option>
        <option value="Altceva">Altceva</option>
      </select>
    
    </div>

    <div class="col-md-12 col-sm-12">
      <label>Mesaju tau</label>
      <textarea formControlName="body" id="body" name="quick-message"></textarea>
      <div *ngIf="f.body.touched && f.body.invalid" class="alert alert-danger">
        <div *ngIf="f.body.errors.required">Introduceti mesajul</div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12">
      <div class="checkbox-group">
        <input type="checkbox" id="terms" name="terms" required="">
        <label for="terms">Sunt de acord cu prelucrarea datelor personale inscrise in Termenii si Conditiile acestui
          website. <span>*</span></label>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 actions">
     
      <button type="submit" class="btn"  [disabled]="!form.valid">Trimite</button>

    </div>
    <div class="col-md-12 col-sm-12">
      <div id="alert-msg" class="alert-msg"></div>
    </div>

  </div>
</form>
